import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";

import Homepage from "../Pages/Home/Home";
import CharacterDesign from "../Pages/CharacterDesign/CharacterDesign";
import Breeding from "../Pages/Breeding/Breeding";

import Transactions from "../Pages/Transactions-History/Transaction";

import { redirectIfNotLoggedIn } from "./loaders";
import AppWrapper from "./AppWrapper";

const AllRoutes = () => {
  return <RouterProvider router={AllRoutesRouter} />;
};
export default AllRoutes;

const AllRoutesRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<AppWrapper />}>
      <Route path="" element={<Homepage />} />

      <Route
        path="characterBuilding"
        element={<CharacterDesign />}
        loader={redirectIfNotLoggedIn}
      />
      <Route
        path="breeding"
        element={<Breeding />}
        loader={redirectIfNotLoggedIn}
      />
      <Route
        path="transaction-history"
        element={<Transactions />}
        loader={redirectIfNotLoggedIn}
      />

      <Route path="*" element={<Homepage />} />
    </Route>
  )
);
