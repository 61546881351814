import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router";

import Header2 from "../../assets/BreedingAssets/heading2.png";
import LogoutComp from "../../Components/Logout/Logout";
import Logo from "../../assets/BreedingAssets/logo.png";
import BreedingBackground from "../../assets/BreedingAssets/BreedingBackground.png";

import useMediaQuery from "@mui/material/useMediaQuery";
import styleGrids from "./DesignGrids.module.css";
import { useDispatch, useSelector } from "react-redux";
import { API, axiosRequestAuth } from "../../Components/api/api";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { setSnackbar } from "../../redux/Snackbar/SnackbarReducder";
import DoneIcon from "@mui/icons-material/Done";
import moment from "moment";
import TabPanel from "../../Components/TabPanels/TabPanels";
import { setMale, setFemale } from "../../redux/GenderReducer/GenderReducer";
import io from "socket.io-client";
import {
  Box,
  Button,
  Tabs,
  Tab,
  Grid,
  Paper,
  Typography,
  Chip,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Divider,
  Modal,
} from "@mui/material";
import { setPotionId } from "../../redux/PotionReducer/PotionReducer";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  width: 800,
  bgcolor: "rgba(109, 220, 254,1)",

  boxShadow: 24,
  p: 4,
};

const historyURL = "api/luxlion/cubs/minted";

const token: any = localStorage.getItem("token");

const Transactions = () => {
  const smallScreenCheck = useMediaQuery("(min-width:450px)");
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [historyState, setHistoryState] = useState<any>([]);

  const hash = useSelector((state: any) => state.queue.txnHash);
  const processQueueNum = useSelector(
    (state: any) => state.queue.currentProcessNumber
  );
  const yourProcessNum = useSelector(
    (state: any) => state.queue.yourProcessNumber
  );
  const loaderredux = useSelector((state: any) => state.queue.loader);

  const [loaderStateHistory, setLoaderStateHistory] = useState<boolean>(false);
  const [nftHash, setNftHash] = useState<any>("");
  const [loaderState, setLoaderState] = useState<boolean>(false);
  const [queuecurrently, setQueuecurrently] = useState<any>("");
  const [yournum, setYournum] = useState<any>("");

  useEffect(() => {
    setNftHash(hash);
    setLoaderState(loaderredux);
    console.log("mba-processQueueNum:", processQueueNum);

    let currentlyProcessingVal;

    if (typeof processQueueNum === "number")
      currentlyProcessingVal = processQueueNum;
    else if (
      typeof processQueueNum === "object" &&
      processQueueNum !== null &&
      "currentlyProcessing" in processQueueNum
    )
      currentlyProcessingVal = processQueueNum.currentlyProcessing;

    if (processQueueNum) setQueuecurrently(currentlyProcessingVal);
    setYournum(yourProcessNum);
  }, [hash, processQueueNum, loaderredux, yourProcessNum]);

  console.log(yourProcessNum, "yourProcessNum");

  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    if (newValue === 1) {
      getHistory();
    }
  };

  useEffect(() => {
    getHistory();
    dispatch(setMale(""));
    dispatch(setFemale(""));
    dispatch(setPotionId(""));
  }, []);

  async function getHistory() {
    setLoaderStateHistory(true);
    try {
      const response: any = await axiosRequestAuth(
        "get",
        historyURL,
        undefined
      );
      console.log("History is here", response.data);
      setHistoryState(response?.data.userChildNfts);
      setLoaderStateHistory(false);
    } catch (err: any) {
      setLoaderStateHistory(false);
      console.log("data is Error", err?.response?.data.message);
    }
  }

  console.log(loaderState, "loader");

  return (
    <>
      <div>
        <img
          src={BreedingBackground}
          alt=""
          style={{ width: "100%", height: "100vh" }}
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            width: "100%",
          }}
        >
          <>
            <Grid container spacing={2}>
              <Grid
                item
                sm={3}
                lg={3}
                md={3}
                xs={12}
                sx={{ paddingTop: 0, display: "flex" }}
                justifyContent="center"
              >
                <Box
                  sx={{ paddingTop: 2, cursor: "pointer" }}
                  onClick={() => navigate("/breeding")}
                >
                  <img src={`${Logo}`} alt="" width="100%" />
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                sm={6}
                sx={{ ml: 0 }}
                justifyContent="right"
              >
                <Box
                  width="100%"
                  borderRadius="8px"
                  sx={{
                    border: "1px solid #6DF8FE",
                    mt: 2,
                    height: "6vh",
                    background: "rgba(70, 70, 70, 0.8)",
                  }}
                >
                  <Box
                    width="80%"
                    borderRadius="8px"
                    sx={{
                      paddingInline: 2,
                      pt: 1,
                      background: "rgba(70, 70, 70, 0.8);",

                      ml: 0,
                      color: "white",
                      zIndex: 99,
                      position: "relative",
                    }}
                  >
                    <img src={`${Header2}`} alt="" width="50%" /> {" :"}
                  </Box>
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                md={3}
                lg={3}
                sm={3}
                sx={{ mt: 3, display: "flex" }}
                justifyContent="center"
              >
                <Box sx={{ textAlign: "right" }}>
                  <LogoutComp />
                </Box>
              </Grid>
            </Grid>
            {smallScreenCheck ? (
              <Box sx={{ paddingInline: 12, pt: 1 }}>
                <Box
                  sx={{
                    width: "100%",
                    background: "rgba(70, 70, 70, 0.8);",
                    borderRadius: 5,
                  }}
                >
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      backgroundColor: "rgba(109, 248, 254, 0.4)",
                      borderTopRightRadius: 5,
                      borderTopLeftRadius: 5,
                      fontWeight: 600,
                    }}
                  >
                    <Tabs
                      value={tabValue}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                      TabIndicatorProps={{
                        sx: { backgroundColor: "#6DF8FE" },
                      }}
                      sx={{
                        "& button.Mui-selected": { color: "#6DF8FE" },
                      }}
                    >
                      <Tab
                        label="Current Process"
                        sx={{ fontWeight: 600, fontFamily: "jedi" }}
                      />
                      <Tab
                        label="History"
                        sx={{ fontWeight: 600, fontFamily: "jedi" }}
                        onClick={() => getHistory}
                      />
                    </Tabs>
                  </Box>

                  <TabPanel value={tabValue} index={0}>
                    <Grid
                      container
                      className={"custom-scroll"}
                      sx={{
                        // background: " rgba(109, 248, 254, 0.6)",
                        borderRadius: "20px",
                        minHeight: "15vh",
                        maxHeight: "68vh",
                        pt: 2,
                        // pb: "15px",
                        px: 2,
                        overflowY: "scroll",
                      }}
                    >
                      <Box
                        minHeight={55}
                        maxHeight={55}
                        minWidth={"100%"}
                        sx={{
                          background: " rgba(109, 248, 254, 0.7)",
                          display: "flex",
                          direction: "row",
                          borderRadius: 2,
                          color: "white",
                          mt: 1,
                        }}
                      >
                        <Grid
                          item
                          sm={2.5}
                          display="flex"
                          justifyContent={"center"}
                        >
                          {loaderState ? (
                            <Typography sx={{ p: 1, fontFamily: "Jedi" }}>
                              current queue number: {processQueueNum}
                            </Typography>
                          ) : (
                            <Typography sx={{ p: 1, fontFamily: "Jedi" }}>
                              Your task is done!
                            </Typography>
                          )}
                        </Grid>
                        <Grid
                          item
                          sm={7}
                          display="flex"
                          justifyContent={"center"}
                        >
                          {" "}
                          {loaderState ? (
                            <Typography sx={{ p: 1, fontFamily: "Jedi" }}>
                              {"Your queue number is :"} {yourProcessNum}
                            </Typography>
                          ) : (
                            <Typography sx={{ p: 1, fontFamily: "Jedi" }}>
                              Processing has been completed for your queue
                              number
                            </Typography>
                          )}
                        </Grid>
                        <Grid
                          item
                          sm={2}
                          display="flex"
                          justifyContent="right"
                          px={2}
                          py={1}
                        >
                          <Box>
                            {!loaderState ? (
                              "Completed"
                            ) : (
                              <CircularProgress sx={{ color: "white" }} />
                            )}
                          </Box>
                        </Grid>
                      </Box>

                      <Divider />
                    </Grid>
                  </TabPanel>
                  {/*////////////////////////////////// Hstory ////////////////////////////////////////////// */}
                  <TabPanel value={tabValue} index={1}>
                    <Grid
                      container
                      className={"custom-scroll"}
                      sx={{
                        // background: " rgba(109, 248, 254, 0.6)",
                        borderRadius: "20px",
                        minHeight: "15vh",
                        maxHeight: "68vh",
                        pt: 2,
                        // pb: "15px",
                        px: 2,
                        overflowY: "scroll",
                      }}
                    >
                      {!loaderStateHistory ? (
                        historyState?.length ? (
                          historyState.map((data: any) => (
                            <Box
                              minHeight={55}
                              maxHeight={55}
                              minWidth={"100%"}
                              sx={{
                                background: " rgba(109, 248, 254, 0.7)",
                                display: "flex",
                                direction: "row",
                                borderRadius: 2,
                                color: "white",
                                mt: 1,
                              }}
                            >
                              <Grid
                                item
                                sm={2.5}
                                display="flex"
                                justifyContent={"center"}
                              >
                                <Typography sx={{ p: 1, fontFamily: "Jedi" }}>
                                  {moment(data.bornAt).format(
                                    "MMMM Do YYYY, h:mm:ss a"
                                  )}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                sm={7}
                                display="flex"
                                justifyContent={"center"}
                              >
                                <Typography
                                  sx={{
                                    p: 1,
                                    fontFamily: "Jedi",
                                    fontWeight: 600,
                                  }}
                                  variant="body1"
                                >
                                  Hash# {data.txnHash}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                sm={2}
                                display="flex"
                                justifyContent="right"
                                px={2}
                                py={1}
                              >
                                <Box>
                                  <Button
                                    sx={{
                                      color: "white",
                                      background: "green",
                                      fontSize: 18,
                                      borderRadius: 10,
                                      mr: 0,
                                      // mt: -1,
                                      border: "1px solid #ffff",
                                      "&:hover": {
                                        background: "rgba(109, 258, 264, 0.6)",
                                        color: "black",
                                      },
                                    }}
                                    href={`${API.ledger}/transactions/${data?.txnHash}`}
                                    target={"_blanK"}
                                  >
                                    <Typography
                                      sx={{ fontFamily: "Jedi", fontSize: 14 }}
                                    >
                                      {"Explorer "}
                                    </Typography>

                                    <OpenInNewIcon fontSize="small" />
                                  </Button>
                                </Box>
                              </Grid>
                            </Box>
                          ))
                        ) : (
                          <>
                            <Grid
                              item
                              sm={12}
                              display="flex"
                              justifyContent={"center"}
                            >
                              <Typography variant="h5" sx={{ color: "white" }}>
                                {"No history found"}
                              </Typography>
                            </Grid>
                          </>
                        )
                      ) : (
                        <Grid
                          item
                          sm={12}
                          display="flex"
                          justifyContent={"center"}
                        >
                          <CircularProgress sx={{ color: "white" }} />
                        </Grid>
                      )}
                    </Grid>
                  </TabPanel>
                </Box>
              </Box>
            ) : (
              /////////////////////////// Mobile View  //////////////////////////////////
              <Box sx={{ paddingInline: 2, pt: 1 }}>
                <Box
                  sx={{
                    width: "100%",
                    background: "rgba(70, 70, 70, 0.8);",
                    borderRadius: 5,
                  }}
                >
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      backgroundColor: "rgba(109, 248, 254, 0.4)",
                      borderTopRightRadius: 5,
                      borderTopLeftRadius: 5,
                      fontWeight: 600,
                    }}
                  >
                    <Tabs
                      value={tabValue}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                      TabIndicatorProps={{
                        sx: { backgroundColor: "#6DF8FE" },
                      }}
                      sx={{
                        "& button.Mui-selected": { color: "#6DF8FE" },
                      }}
                    >
                      <Tab
                        label="Current Process"
                        sx={{ fontWeight: 600, fontFamily: "jedi" }}
                      />
                      <Tab
                        label="History"
                        sx={{ fontWeight: 600, fontFamily: "jedi" }}
                        onClick={() => getHistory()}
                      />
                    </Tabs>
                  </Box>
                  <TabPanel value={tabValue} index={0}>
                    {" "}
                    <Grid
                      container
                      className={"custom-scroll"}
                      sx={{
                        // background: " rgba(109, 248, 254, 0.6)",
                        borderRadius: "20px",
                        minHeight: "15vh",
                        maxHeight: "68vh",
                        pt: 2,
                        // pb: "15px",
                        px: 2,
                        overflowY: "scroll",
                      }}
                    >
                      <Box
                        minHeight={10}
                        maxHeight={50}
                        minWidth={"100%"}
                        sx={{
                          background: " rgba(109, 248, 254, 0.7)",
                          display: "flex",
                          direction: "row",
                          borderRadius: 2,
                          color: "white",
                          mt: 1,
                        }}
                      >
                        <Grid
                          item
                          sm={6}
                          display="flex"
                          justifyContent={"center"}
                        >
                          {loaderState ? (
                            <Typography sx={{ p: 1, fontFamily: "Jedi" }}>
                              current queue number: {processQueueNum}
                            </Typography>
                          ) : (
                            <Typography sx={{ p: 1, fontFamily: "Jedi" }}>
                              Your Task is done!
                            </Typography>
                          )}
                        </Grid>

                        <Grid
                          item
                          sm={6}
                          display="flex"
                          justifyContent="right"
                          px={2}
                          py={1}
                        >
                          <Box>
                            {!loaderState ? (
                              "Completed"
                            ) : (
                              <CircularProgress sx={{ color: "white" }} />
                            )}
                          </Box>
                        </Grid>
                      </Box>

                      <Divider />
                    </Grid>
                  </TabPanel>
                  <TabPanel value={tabValue} index={1}>
                    <Grid
                      container
                      className={"custom-scroll"}
                      sx={{
                        // background: " rgba(109, 248, 254, 0.6)",
                        borderRadius: "20px",
                        minHeight: "15vh",
                        maxHeight: "68vh",
                        pt: 2,
                        // pb: "15px",
                        px: 2,
                        overflowY: "scroll",
                      }}
                    >
                      {historyState?.map((data: any) => (
                        <Box
                          minHeight={55}
                          maxHeight={55}
                          minWidth={"100%"}
                          sx={{
                            background: " rgba(109, 248, 254, 0.7)",
                            display: "flex",
                            direction: "row",
                            borderRadius: 2,
                            color: "white",
                            mt: 1,
                          }}
                        >
                          <Grid
                            item
                            sm={4}
                            display="flex"
                            justifyContent={"center"}
                          >
                            <Typography sx={{ p: 1, fontFamily: "Jedi" }}>
                              {moment(data.bornAt).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )}
                            </Typography>
                          </Grid>
                          {/* <Grid
                              item
                              sm={7}
                              display="flex"
                              justifyContent={"center"}
                            >
                              <Typography
                                sx={{
                                  p: 1,
                                  fontFamily: "Jedi",
                                  fontWeight: 600,
                                }}
                                variant="body1"
                              >
                                Hash# {data.txnHash}
                              </Typography>
                            </Grid> */}
                          <Grid
                            item
                            sm={2}
                            display="flex"
                            justifyContent="right"
                            px={2}
                            py={1}
                          >
                            <Box>
                              <Button
                                sx={{
                                  color: "white",
                                  background: "green",
                                  fontSize: 18,
                                  borderRadius: 10,
                                  mr: 0,
                                  // mt: -1,
                                  border: "1px solid #ffff",
                                  "&:hover": {
                                    background: "rgba(109, 258, 264, 0.6)",
                                    color: "black",
                                  },
                                }}
                                href={`${API.ledger}/transactions/${data?.txnHash}`}
                                target={"_blanK"}
                              >
                                <Typography
                                  sx={{ fontFamily: "Jedi", fontSize: 14 }}
                                >
                                  {"Explorer "}
                                </Typography>

                                <OpenInNewIcon fontSize="small" />
                              </Button>
                            </Box>
                          </Grid>
                        </Box>
                      ))}
                      <Divider />
                    </Grid>
                  </TabPanel>
                </Box>
              </Box>
            )}
          </>
        </div>
      </div>
    </>
  );
};
export default Transactions;
