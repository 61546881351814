import { Box, Typography } from "@mui/material";

interface NoNftFoundProps {
  gender: "male" | "female";
}

const NoNftFound: React.FC<NoNftFoundProps> = ({ gender }) => {
  const text =
    gender === "male" ? "No male NFT found!" : "No female NFT found!";

  return (
    <Box justifyContent="center" minHeight="50px" mt={5} mb={5}>
      <Typography variant="h4" sx={{ color: "white" }}>
        {text}
      </Typography>
    </Box>
  );
};

export default NoNftFound;
