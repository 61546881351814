import React, { useState, useEffect } from "react";
import { makeStyles, Theme } from "@mui/material/styles";
import BreedingBackground from "../../../assets/Background/backgroundImageMbl.jpg";
import "./TabPanels.css";
import Canvas from "../../../Components/Canvas/Canvas";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router";
import BackgroundIcon from "../../../assets/Icons/background.png";
import BodyIcon from "../../../assets/Icons/body.png";
import ClothesIcon from "../../../assets/Icons/dress.png";
import EyeIcon from "../../../assets/Icons/eye.png";
import HairIcon from "../../../assets/Icons/hair.png";
import HeadIcon from "../../../assets/Icons/hat.png";
import AccessotiesIcon from "../../../assets/Icons/Access.svg";
import GlassesIcon from "../../../assets/Icons/Eyewear.svg";
import MouthIcon from "../../../assets/Icons/mouth.png";
import LeftGridPanel from "../../../Components/DesignGrids/GridPanels/LeftGridPanels";
import Logo from "../../../assets/BreedingAssets/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../../redux/Snackbar/SnackbarReducder";
import { API, axiosRequestAuth } from "../../../Components/api/api";
import { setCurentProcessNumber } from "../../../redux/QueueProcess/QueueProcessReducer";
import { setYorProcessNumber } from "../../../redux/QueueProcess/QueueProcessReducer";
import { connectSocket } from "../../../redux/SocketReducer/SocketReducer";
import {
  Button,
  Collapse,
  Paper,
  Grid,
  Modal,
  Box,
  Backdrop,
  Switch,
  FormControlLabel,
  Slide,
  Fade,
  IconButton,
  Checkbox,
  Typography,
} from "@mui/material";

import styleGrids from "./DesignGridsPanels.module.css";
import io from "socket.io-client";
import { setApiHit } from "../../../redux/LoaderReducer/LoaderReducer";
import { setLoader } from "../../../redux/QueueProcess/QueueProcessReducer";
import Cross from "../../../assets/cross.png";
import Background1 from "../../../assets/DesignAssets/Background/background1.png";
import Background2 from "../../../assets/DesignAssets/Background/background2.png";
import Background3 from "../../../assets/DesignAssets/Background/background3.png";
import Background4 from "../../../assets/DesignAssets/Background/background4.png";
import Background5 from "../../../assets/DesignAssets/Background/background5.png";
import Background6 from "../../../assets/DesignAssets/Background/background6.png";
import Background7 from "../../../assets/DesignAssets/Background/background7.png";
import Background8 from "../../../assets/DesignAssets/Background/background8.png";
import Background9 from "../../../assets/DesignAssets/Background/background9.png";
import Background10 from "../../../assets/DesignAssets/Background/background10.png";
import Background11 from "../../../assets/DesignAssets/Background/background11.png";
import Background12 from "../../../assets/DesignAssets/Background/background12.png";
import Background13 from "../../../assets/DesignAssets/Background/background13.png";
import Background14 from "../../../assets/DesignAssets/Background/background14.png";
import Background15 from "../../../assets/DesignAssets/Background/background15.png";
import Background16 from "../../../assets/DesignAssets/Background/background16.png";
import Background17 from "../../../assets/DesignAssets/Background/background17.png";
import Background18 from "../../../assets/DesignAssets/Background/background18.png";
import Background19 from "../../../assets/DesignAssets/Background/background19.png";
import Background20 from "../../../assets/DesignAssets/Background/background20.png";
import Background21 from "../../../assets/DesignAssets/Background/background21.png";
import Background22 from "../../../assets/DesignAssets/Background/background22.png";
import Background23 from "../../../assets/DesignAssets/Background/background23.png";
import Background24 from "../../../assets/DesignAssets/Background/background24.png";
import Background25 from "../../../assets/DesignAssets/Background/background25.png";
import Background26 from "../../../assets/DesignAssets/Background/background26.png";
import Background27 from "../../../assets/DesignAssets/Background/background27.png";
import Background28 from "../../../assets/DesignAssets/Background/background28.png";
import Background29 from "../../../assets/DesignAssets/Background/background29.png";
import Background30 from "../../../assets/DesignAssets/Background/background30.png";
import Background31 from "../../../assets/DesignAssets/Background/background31.png";
import Background32 from "../../../assets/DesignAssets/Background/background32.png";
import Background33 from "../../../assets/DesignAssets/Background/background33.png";
import Background34 from "../../../assets/DesignAssets/Background/background34.png";
import Background35 from "../../../assets/DesignAssets/Background/background35.png";
import Background36 from "../../../assets/DesignAssets/Background/background36.png";
import Background37 from "../../../assets/DesignAssets/Background/background37.png";
import Background38 from "../../../assets/DesignAssets/Background/background38.png";
import Background39 from "../../../assets/DesignAssets/Background/background39.png";
import Background40 from "../../../assets/DesignAssets/Background/background40.png";
import Background41 from "../../../assets/DesignAssets/Background/background41.png";
import Background42 from "../../../assets/DesignAssets/Background/background41a.png";
import Background43 from "../../../assets/DesignAssets/Background/background42.png";
import Background44 from "../../../assets/DesignAssets/Background/background43.png";
import Background45 from "../../../assets/DesignAssets/Background/background44.png";
import Background46 from "../../../assets/DesignAssets/Background/background45.png";
import Background47 from "../../../assets/DesignAssets/Background/background46.png";
import Background48 from "../../../assets/DesignAssets/Background/background47.png";
import Background49 from "../../../assets/DesignAssets/Background/background48.png";
import Background50 from "../../../assets/DesignAssets/Background/background49.png";
import Background51 from "../../../assets/DesignAssets/Background/background50.png";
import Background52 from "../../../assets/DesignAssets/Background/background51.png";
import Background53 from "../../../assets/DesignAssets/Background/background52.png";
import Background54 from "../../../assets/DesignAssets/Background/background53.png";
import Background55 from "../../../assets/DesignAssets/Background/background54.png";
import Background56 from "../../../assets/DesignAssets/Background/background55.png";
import Background57 from "../../../assets/DesignAssets/Background/background56.png";
import Background58 from "../../../assets/DesignAssets/Background/background57.png";
import Background59 from "../../../assets/DesignAssets/Background/background58.png";

import Head1 from "../../../assets/DesignAssets/HeadGear/transparent.png";
import Head2 from "../../../assets/DesignAssets/HeadGear/HEADGEAR2.png";
import Head3 from "../../../assets/DesignAssets/HeadGear/HEADGEAR3.png";
import Head4 from "../../../assets/DesignAssets/HeadGear/HEADGEAR4.png";
import Head5 from "../../../assets/DesignAssets/HeadGear/HEADGEAR5.png";
import Head6 from "../../../assets/DesignAssets/HeadGear/HEADGEAR6.png";
import Head7 from "../../../assets/DesignAssets/HeadGear/HEADGEAR7.png";
import Head8 from "../../../assets/DesignAssets/HeadGear/HEADGEAR8.png";
import Head9 from "../../../assets/DesignAssets/HeadGear/HEADGEAR9.png";
import Head10 from "../../../assets/DesignAssets/HeadGear/HEADGEAR10.png";
import Head11 from "../../../assets/DesignAssets/HeadGear/HEADGEAR11.png";
import Head12 from "../../../assets/DesignAssets/HeadGear/HEADGEAR12.png";
import Head13 from "../../../assets/DesignAssets/HeadGear/HEADGEAR13.png";
import Head14 from "../../../assets/DesignAssets/HeadGear/HEADGEAR14.png";
import Head15 from "../../../assets/DesignAssets/HeadGear/HEADGEAR15.png";
import Head16 from "../../../assets/DesignAssets/HeadGear/HEADGEAR16.png";
import Head17 from "../../../assets/DesignAssets/HeadGear/HEADGEAR17.png";
import Head18 from "../../../assets/DesignAssets/HeadGear/HEADGEAR18.png";
import Head19 from "../../../assets/DesignAssets/HeadGear/HEADGEAR19.png";
import Head20 from "../../../assets/DesignAssets/HeadGear/HEADGEAR20.png";
import Head21 from "../../../assets/DesignAssets/HeadGear/HEADGEAR1.png";

import skin1 from "../../../assets/DesignAssets/SkinFemale/SKIN1.png";
import skin2 from "../../../assets/DesignAssets/SkinFemale/SKIN2.png";
import skin3 from "../../../assets/DesignAssets/SkinFemale/SKIN3.png";
import skin4 from "../../../assets/DesignAssets/SkinFemale/SKIN4.png";
import skin5 from "../../../assets/DesignAssets/SkinFemale/SKIN5.png";
import skin6 from "../../../assets/DesignAssets/SkinFemale/SKIN6.png";
import skin7 from "../../../assets/DesignAssets/SkinFemale/SKIN7.png";
import skin8 from "../../../assets/DesignAssets/SkinFemale/SKIN8.png";
import skin9 from "../../../assets/DesignAssets/SkinFemale/SKIN9.png";
import skin10 from "../../../assets/DesignAssets/SkinFemale/SKIN10.png";
import skin11 from "../../../assets/DesignAssets/SkinFemale/SKIN11.png";
import skin12 from "../../../assets/DesignAssets/SkinFemale/SKIN12.png";
import skin13 from "../../../assets/DesignAssets/SkinFemale/SKIN13.png";
import skin14 from "../../../assets/DesignAssets/SkinFemale/SKIN14.png";
import skin15 from "../../../assets/DesignAssets/SkinFemale/SKIN15.png";
import skin16 from "../../../assets/DesignAssets/SkinFemale/SKIN16.png";
import skin17 from "../../../assets/DesignAssets/SkinFemale/SKIN17.png";
import skin18 from "../../../assets/DesignAssets/SkinFemale/SKIN18.png";
import skin19 from "../../../assets/DesignAssets/SkinFemale/SKIN19.png";
import skin20 from "../../../assets/DesignAssets/SkinFemale/SKIN20.png";
import skin21 from "../../../assets/DesignAssets/SkinFemale/SKIN21.png";
import skin22 from "../../../assets/DesignAssets/SkinFemale/SKIN22.png";
import skin23 from "../../../assets/DesignAssets/SkinFemale/SKIN23.png";
import skin24 from "../../../assets/DesignAssets/SkinFemale/SKIN24.png";
import skin25 from "../../../assets/DesignAssets/SkinFemale/SKIN25.png";
import skin26 from "../../../assets/DesignAssets/SkinFemale/SKIN26.png";
import skin27 from "../../../assets/DesignAssets/SkinFemale/SKIN27.png";
import skin28 from "../../../assets/DesignAssets/SkinFemale/SKIN28.png";
import skin29 from "../../../assets/DesignAssets/SkinFemale/SKIN29.png";
import skin30 from "../../../assets/DesignAssets/SkinFemale/SKIN30.png";
import skin31 from "../../../assets/DesignAssets/SkinFemale/SKIN31.png";

import Clothes1 from "../../../assets/DesignAssets/Clothes/transparent.png";
import Clothes2 from "../../../assets/DesignAssets/Clothes/CLOTHES2.png";
import Clothes3 from "../../../assets/DesignAssets/Clothes/CLOTHES3.png";
import Clothes4 from "../../../assets/DesignAssets/Clothes/CLOTHES4.png";
import Clothes5 from "../../../assets/DesignAssets/Clothes/CLOTHES5.png";
import Clothes6 from "../../../assets/DesignAssets/Clothes/CLOTHES6.png";
import Clothes7 from "../../../assets/DesignAssets/Clothes/CLOTHES7.png";
import Clothes8 from "../../../assets/DesignAssets/Clothes/CLOTHES8.png";
import Clothes9 from "../../../assets/DesignAssets/Clothes/CLOTHES9.png";
import Clothes10 from "../../../assets/DesignAssets/Clothes/CLOTHES10.png";
import Clothes11 from "../../../assets/DesignAssets/Clothes/CLOTHES11.png";
import Clothes12 from "../../../assets/DesignAssets/Clothes/CLOTHES12.png";
import Clothes13 from "../../../assets/DesignAssets/Clothes/CLOTHES13.png";
import Clothes14 from "../../../assets/DesignAssets/Clothes/CLOTHES14.png";
import Clothes15 from "../../../assets/DesignAssets/Clothes/CLOTHES15.png";
import Clothes16 from "../../../assets/DesignAssets/Clothes/CLOTHES1.png";

import Accesories1 from "../../../assets/DesignAssets/Accessories/transparent.png";
import Accesories2 from "../../../assets/DesignAssets/Accessories/Accessories2.png";
import Accesories3 from "../../../assets/DesignAssets/Accessories/Accessories3.png";
import Accesories4 from "../../../assets/DesignAssets/Accessories/Accessories4.png";
import Accesories5 from "../../../assets/DesignAssets/Accessories/Accessories5.png";
import Accesories6 from "../../../assets/DesignAssets/Accessories/Accessories6.png";
import Accesories7 from "../../../assets/DesignAssets/Accessories/Accessories7.png";
import Accesories8 from "../../../assets/DesignAssets/Accessories/Accessories8.png";
import Accesories9 from "../../../assets/DesignAssets/Accessories/Accessories9.png";
import Accesories10 from "../../../assets/DesignAssets/Accessories/Accessories10.png";
import Accesories11 from "../../../assets/DesignAssets/Accessories/Accessories11.png";
import Accesories12 from "../../../assets/DesignAssets/Accessories/Accessories1.png";

import eyeWear1 from "../../../assets/DesignAssets/EyeWear/transparent.png";
import eyeWear2 from "../../../assets/DesignAssets/EyeWear/EYEWEAR2.png";
import eyeWear3 from "../../../assets/DesignAssets/EyeWear/EYEWEAR3.png";
import eyeWear4 from "../../../assets/DesignAssets/EyeWear/EYEWEAR4.png";
import eyeWear5 from "../../../assets/DesignAssets/EyeWear/EYEWEAR5.png";
import eyeWear6 from "../../../assets/DesignAssets/EyeWear/EYEWEAR6.png";
import eyeWear7 from "../../../assets/DesignAssets/EyeWear/EYEWEAR7.png";
import eyeWear8 from "../../../assets/DesignAssets/EyeWear/EYEWEAR8.png";
import eyeWear9 from "../../../assets/DesignAssets/EyeWear/EYEWEAR9.png";
import eyeWear10 from "../../../assets/DesignAssets/EyeWear/EYEWEAR10.png";
import eyeWear11 from "../../../assets/DesignAssets/EyeWear/EYEWEAR11.png";
import eyeWear12 from "../../../assets/DesignAssets/EyeWear/EYEWEAR12.png";
import eyeWear13 from "../../../assets/DesignAssets/EyeWear/EYEWEAR13.png";
import eyeWear14 from "../../../assets/DesignAssets/EyeWear/EYEWEAR14.png";
import eyeWear15 from "../../../assets/DesignAssets/EyeWear/EYEWEAR1.png";

import mouth1 from "../../../assets/DesignAssets/MaleMouth/MOUTH1.png";
import mouth2 from "../../../assets/DesignAssets/MaleMouth/MOUTH2.png";
import mouth3 from "../../../assets/DesignAssets/MaleMouth/MOUTH3.png";
import mouth4 from "../../../assets/DesignAssets/MaleMouth/MOUTH4.png";
import mouth5 from "../../../assets/DesignAssets/MaleMouth/MOUTH5.png";
import mouth6 from "../../../assets/DesignAssets/MaleMouth/MOUTH6.png";
import mouth7 from "../../../assets/DesignAssets/MaleMouth/MOUTH7.png";
import mouth8 from "../../../assets/DesignAssets/MaleMouth/MOUTH8.png";
import mouth9 from "../../../assets/DesignAssets/MaleMouth/MOUTH9.png";

import mouth10 from "../../../assets/DesignAssets/MaleMouth/MOUTH10.png";
import mouth11 from "../../../assets/DesignAssets/MaleMouth/MOUTH11.png";
import mouth12 from "../../../assets/DesignAssets/MaleMouth/MOUTH12.png";
import mouth13 from "../../../assets/DesignAssets/MaleMouth/MOUTH13.png";
import mouth14 from "../../../assets/DesignAssets/MaleMouth/MOUTH14.png";
import mouth15 from "../../../assets/DesignAssets/MaleMouth/MOUTH15.png";

/////////// Female eyes//////////////////////////////////////
import eyes1 from "../../../assets/DesignAssets/maleEyes/EYE1.png";
import eyes2 from "../../../assets/DesignAssets/maleEyes/EYE2.png";
import eyes3 from "../../../assets/DesignAssets/maleEyes/EYE3.png";
import eyes4 from "../../../assets/DesignAssets/maleEyes/EYE4.png";
import eyes5 from "../../../assets/DesignAssets/maleEyes/EYE5.png";
import eyes6 from "../../../assets/DesignAssets/maleEyes/EYE6.png";
import eyes7 from "../../../assets/DesignAssets/maleEyes/EYE7.png";
import eyes8 from "../../../assets/DesignAssets/maleEyes/EYE8.png";
import eyes9 from "../../../assets/DesignAssets/maleEyes/EYE9.png";

////////////////////// male eyes//////////////////////////////////////
import eyes10 from "../../../assets/DesignAssets/maleEyes/EYE10.png";
import eyes11 from "../../../assets/DesignAssets/maleEyes/EYE11.png";
import eyes12 from "../../../assets/DesignAssets/maleEyes/EYE12.png";
import eyes13 from "../../../assets/DesignAssets/maleEyes/EYE13.png";
import eyes14 from "../../../assets/DesignAssets/maleEyes/EYE14.png";
import eyes15 from "../../../assets/DesignAssets/maleEyes/EYE15.png";
import eyes16 from "../../../assets/DesignAssets/maleEyes/EYE16.png";
import eyes17 from "../../../assets/DesignAssets/maleEyes/EYE17.png";
import eyes18 from "../../../assets/DesignAssets/maleEyes/EYE18.png";
import eyes19 from "../../../assets/DesignAssets/maleEyes/EYE19.png";
import eyes20 from "../../../assets/DesignAssets/maleEyes/EYE20.png";

import LogoutComp from "../../../Components/Logout/Logout";
import { breedRequestBody } from "../../../Components/DesignGrids/DesginGrids";
import { setFemale, setMale } from "../../../redux/GenderReducer/GenderReducer";
import { setPotionId } from "../../../redux/PotionReducer/PotionReducer";

const AccessoriesImg = [
  { key: 1, img: Accesories1, name: "Accesories1" },
  { key: 2, img: Accesories2, name: "Accesories2" },
  { key: 3, img: Accesories3, name: "Accesories3" },
  { key: 4, img: Accesories4, name: "Accesories4" },
  { key: 5, img: Accesories5, name: "Accesories5" },
  { key: 6, img: Accesories6, name: "Accesories6" },
  { key: 7, img: Accesories7, name: "Accesories7" },
  { key: 8, img: Accesories8, name: "Accesories8" },
  { key: 9, img: Accesories9, name: "Accesories9" },
  { key: 10, img: Accesories10, name: "Accesories10" },
  { key: 11, img: Accesories11, name: "Accesories11" },
  { key: 12, img: Accesories12, name: "Accesories12" },
];

const maleEyesImg = [
  { key: 1, img: eyes1, name: "eyes1" },
  { key: 2, img: eyes2, name: "eyes2" },
  { key: 3, img: eyes3, name: "eyes3" },
  { key: 4, img: eyes4, name: "eyes4" },
  { key: 5, img: eyes5, name: "eyes5" },
  { key: 6, img: eyes6, name: "eyes6" },
  { key: 7, img: eyes7, name: "eyes7" },
  { key: 8, img: eyes8, name: "eyes8" },
  { key: 9, img: eyes9, name: "eyes9" },
  { key: 10, img: eyes10, name: "eyes10" },
  { key: 11, img: eyes11, name: "eyes11" },
  { key: 12, img: eyes12, name: "eyes12" },
  { key: 13, img: eyes13, name: "eyes13" },
  { key: 14, img: eyes14, name: "eyes14" },
  { key: 15, img: eyes15, name: "eyes15" },
  { key: 16, img: eyes16, name: "eyes16" },
  { key: 17, img: eyes17, name: "eyes17" },
  { key: 18, img: eyes18, name: "eyes18" },
  { key: 19, img: eyes19, name: "eyes19" },
  { key: 20, img: eyes20, name: "eyes20" },
];

const EyeWearImg = [
  { key: 1, img: eyeWear1, name: "EyeWear1" },
  { key: 2, img: eyeWear2, name: "EyeWear2" },
  { key: 3, img: eyeWear3, name: "EyeWear3" },
  { key: 4, img: eyeWear4, name: "EyeWear4" },
  { key: 5, img: eyeWear5, name: "EyeWear5" },
  { key: 6, img: eyeWear6, name: "EyeWear6" },
  { key: 7, img: eyeWear7, name: "EyeWear7" },
  { key: 8, img: eyeWear8, name: "EyeWear8" },
  { key: 9, img: eyeWear9, name: "EyeWear9" },
  { key: 10, img: eyeWear10, name: "EyeWear10" },
  { key: 11, img: eyeWear11, name: "EyeWear11" },
  { key: 12, img: eyeWear12, name: "EyeWear12" },
  { key: 13, img: eyeWear13, name: "EyeWear13" },
  { key: 14, img: eyeWear14, name: "EyeWear14" },
  { key: 15, img: eyeWear15, name: "EyeWear15" },
];
const maleMouthImg = [
  { key: 1, img: mouth1, name: "mouth1" },
  { key: 2, img: mouth2, name: "mouth2" },
  { key: 3, img: mouth3, name: "mouth3" },
  { key: 4, img: mouth4, name: "mouth4" },
  { key: 5, img: mouth5, name: "mouth5" },
  { key: 6, img: mouth6, name: "mouth6" },
  { key: 7, img: mouth7, name: "mouth7" },
  { key: 8, img: mouth8, name: "mouth8" },
  { key: 9, img: mouth9, name: "mouth9" },
  { key: 10, img: mouth10, name: "mouth10" },
  { key: 11, img: mouth11, name: "mouth11" },
  { key: 12, img: mouth12, name: "mouth12" },
  { key: 13, img: mouth13, name: "mouth13" },
  { key: 14, img: mouth14, name: "mouth14" },
  { key: 15, img: mouth15, name: "mouth15" },
];

const skinImg = [
  { key: 1, img: skin1, name: "skin1" },
  { key: 2, img: skin2, name: "skin2" },
  { key: 3, img: skin3, name: "skin3" },
  { key: 4, img: skin4, name: "skin4" },
  { key: 5, img: skin5, name: "skin5" },
  { key: 6, img: skin6, name: "skin6" },
  { key: 7, img: skin7, name: "skin7" },
  { key: 8, img: skin8, name: "skin8" },
  { key: 9, img: skin9, name: "skin9" },
  { key: 10, img: skin10, name: "skin10" },
  { key: 11, img: skin11, name: "skin11" },
  { key: 12, img: skin12, name: "skin12" },
  { key: 13, img: skin13, name: "skin13" },
  { key: 14, img: skin14, name: "skin14" },
  { key: 15, img: skin15, name: "skin15" },
  { key: 16, img: skin16, name: "skin16" },
  { key: 17, img: skin17, name: "skin17" },
  { key: 18, img: skin18, name: "skin18" },
  { key: 19, img: skin19, name: "skin19" },
  { key: 20, img: skin20, name: "skin20" },
  { key: 21, img: skin21, name: "skin21" },
  { key: 22, img: skin22, name: "skin22" },
  { key: 23, img: skin23, name: "skin23" },
  { key: 24, img: skin24, name: "skin24" },
  { key: 25, img: skin25, name: "skin25" },
  { key: 26, img: skin26, name: "skin26" },
  { key: 27, img: skin27, name: "skin27" },
  { key: 28, img: skin28, name: "skin28" },
  { key: 29, img: skin29, name: "skin29" },
  { key: 30, img: skin30, name: "skin30" },
  { key: 31, img: skin31, name: "skin31" },
];
const eyeImg = [
  { key: 1, img: eyes1, name: "eyes1" },
  { key: 2, img: eyes2, name: "eyes2" },
  { key: 3, img: eyes3, name: "eyes3" },
];
const HeadGearImg = [
  { key: 1, img: Head1, name: "head1" },
  { key: 2, img: Head2, name: "head2" },
  { key: 3, img: Head3, name: "head3" },
  { key: 4, img: Head4, name: "head4" },
  { key: 5, img: Head5, name: "head5" },
  { key: 6, img: Head6, name: "head6" },
  { key: 7, img: Head7, name: "head7" },
  { key: 8, img: Head8, name: "head8" },
  { key: 9, img: Head9, name: "head9" },
  { key: 10, img: Head10, name: "head10" },
  { key: 11, img: Head11, name: "head11" },
  { key: 12, img: Head12, name: "head12" },
  { key: 13, img: Head13, name: "head13" },
  { key: 14, img: Head14, name: "head14" },
  { key: 15, img: Head15, name: "head15" },
  { key: 16, img: Head16, name: "head16" },
  { key: 17, img: Head17, name: "head17" },
  { key: 18, img: Head18, name: "head18" },
  { key: 19, img: Head19, name: "head19" },
  { key: 20, img: Head20, name: "head20" },
  { key: 21, img: Head21, name: "head21" },
];

const clothsImg = [
  { key: 1, img: Clothes1, name: "clothes1" },
  { key: 2, img: Clothes2, name: "clothes2" },
  { key: 3, img: Clothes3, name: "clothes3" },
  { key: 4, img: Clothes4, name: "clothes4" },
  { key: 5, img: Clothes5, name: "clothes5" },
  { key: 6, img: Clothes6, name: "clothes6" },
  { key: 7, img: Clothes7, name: "clothes7" },
  { key: 8, img: Clothes8, name: "clothes8" },
  { key: 9, img: Clothes9, name: "clothes9" },
  { key: 10, img: Clothes10, name: "clothes10" },
  { key: 11, img: Clothes11, name: "clothes11" },
  { key: 12, img: Clothes12, name: "clothes12" },
  { key: 13, img: Clothes13, name: "clothes13" },
  { key: 14, img: Clothes14, name: "clothes14" },
  { key: 15, img: Clothes15, name: "clothes15" },
  { key: 16, img: Clothes16, name: "clothes16" },
];

const backgroundImg = [
  { key: 1, img: Background1, name: "bckgrd1" },
  { key: 2, img: Background2, name: "bckgrd2" },
  { key: 3, img: Background3, name: "bckgrd3" },
  { key: 4, img: Background4, name: "bckgrd4" },
  { key: 5, img: Background5, name: "bckgrd5" },
  { key: 6, img: Background6, name: "bckgrd6" },
  { key: 7, img: Background7, name: "bckgrd7" },
  { key: 8, img: Background8, name: "bckgrd8" },
  { key: 9, img: Background9, name: "bckgrd9" },
  { key: 10, img: Background10, name: "bckgrd10" },
  { key: 11, img: Background11, name: "bckgrd11" },
  { key: 12, img: Background12, name: "bckgrd12" },
  { key: 13, img: Background13, name: "bckgrd13" },
  { key: 14, img: Background14, name: "bckgrd14" },
  { key: 15, img: Background15, name: "bckgrd15" },
  { key: 16, img: Background16, name: "bckgrd16" },
  { key: 17, img: Background17, name: "bckgrd17" },
  { key: 18, img: Background18, name: "bckgrd18" },
  { key: 19, img: Background19, name: "bckgrd19" },
  { key: 20, img: Background20, name: "bckgrd20" },
  { key: 21, img: Background21, name: "bckgrd21" },
  { key: 22, img: Background22, name: "bckgrd22" },
  { key: 23, img: Background23, name: "bckgrd23" },
  { key: 24, img: Background24, name: "bckgrd24" },
  { key: 25, img: Background25, name: "bckgrd25" },
  { key: 26, img: Background26, name: "bckgrd26" },
  { key: 27, img: Background27, name: "bckgrd27" },
  { key: 28, img: Background28, name: "bckgrd28" },
  { key: 29, img: Background29, name: "bckgrd29" },
  { key: 30, img: Background30, name: "bckgrd30" },
  { key: 31, img: Background31, name: "bckgrd31" },
  { key: 32, img: Background32, name: "bckgrd32" },
  { key: 33, img: Background33, name: "bckgrd33" },
  { key: 34, img: Background34, name: "bckgrd34" },
  { key: 35, img: Background35, name: "bckgrd35" },
  { key: 36, img: Background36, name: "bckgrd36" },
  { key: 37, img: Background37, name: "bckgrd37" },
  { key: 38, img: Background38, name: "bckgrd38" },
  { key: 39, img: Background39, name: "bckgrd39" },
  { key: 40, img: Background40, name: "bckgrd40" },
  { key: 41, img: Background41, name: "bckgrd41" },
  { key: 42, img: Background42, name: "bckgrd42" },
  { key: 43, img: Background43, name: "bckgrd43" },
  { key: 44, img: Background44, name: "bckgrd44" },
  { key: 45, img: Background45, name: "bckgrd45" },
  { key: 46, img: Background46, name: "bckgrd46" },
  { key: 47, img: Background47, name: "bckgrd47" },
  { key: 48, img: Background48, name: "bckgrd48" },
  { key: 49, img: Background49, name: "bckgrd49" },
  { key: 50, img: Background50, name: "bckgrd50" },
  { key: 51, img: Background51, name: "bckgrd51" },
  { key: 52, img: Background52, name: "bckgrd52" },
  { key: 53, img: Background53, name: "bckgrd53" },
  { key: 54, img: Background54, name: "bckgrd54" },
  { key: 55, img: Background55, name: "bckgrd55" },
  { key: 56, img: Background56, name: "bckgrd56" },
  { key: 57, img: Background57, name: "bckgrd57" },
  { key: 58, img: Background58, name: "bckgrd58" },
  { key: 59, img: Background59, name: "bckgrd59" },
];

const crossImg = [{ key: 1, img: Cross, name: "none" }];

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  width: 250,
  bgcolor: "rgba(109, 220, 254,1)",
  p: 4,
};

const TransitionGrid = () => {
  const [activeBackground, setActiveBackground] = useState<number>(0);
  const [openBackground, setOpenBackground] = React.useState(false);
  const [openHeadgear, setOpenHeadgear] = React.useState(false);
  const [openSkin, setOpenSkin] = React.useState(false);
  const [openMouth, setOpenMouth] = React.useState(false);
  const [openAccessories, setOpenAccessories] = React.useState(false);
  const [openEyes, setOpenEyes] = React.useState(false);
  const [openEyeswear, setOpenEyeswear] = React.useState(false);
  const [openClothes, setOpenClothes] = React.useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [checked, setChecked] = React.useState(false);
  const [activeSkin, setActiveSkin] = useState<number>(0);
  const [activeClothes, setActiveClothes] = useState<number>(0);
  const [mintcheck, setMintCheck] = useState<boolean>(false);
  const [activeEyesWear, setActiveEyesWear] = useState<number>(0);
  const [activeAccessories, setActiveAccessories] = useState<number>(0);
  const [activeMouth, setActiveMouth] = useState<number>(0);
  const [activeEyes, setActiveEyes] = useState<number>(0);
  const [modalOpen, setOpenModal] = useState<boolean>(false);
  const [modalOpen2, setOpenModal2] = useState<boolean>(false);
  const [activeHeadGear, setActiveHeadGear] = useState<number>(0);
  const containerRef = React.useRef(null);
  const [checkBoxCheck1, setcheckBoxCheck1] = useState(true);
  const [checkBoxCheck2, setcheckBoxCheck2] = useState(false);
  const maleToken = useSelector((state: any) => state.gender.male);
  const potionToken = useSelector((state: any) => state.potion?.potionId);
  const [uri, setUri] = useState<string>("");
  const femaleToken = useSelector((state: any) => state.gender.female);
  let socketio = useSelector((state: any) => state.socketR.socket);

  console.log(potionToken, "potionToken");
  useEffect(() => {
    dispatch(setApiHit(false));
  }, []);
  const [characterObjectState, setCharacterObjectState] =
    useState<breedRequestBody>({
      male: {
        tokenId: maleToken,
      },
      female: {
        tokenId: femaleToken,
      },
      potion: {
        tokenId: potionToken,
      },
      childTraits: {
        gender: "MALE",
        background: "",
        skin: "",
        eyewear: "none",
        mouth: "",
        clothing: "none",
        eyes: "",
        headgear: "none",
        accesories: "none",
      },
      uri: "",
    });

  const [characterObjectName, setCharacterObjectName] =
    useState<breedRequestBody>({
      male: {
        tokenId: maleToken,
      },
      female: {
        tokenId: femaleToken,
      },
      potion: {
        tokenId: potionToken,
      },
      childTraits: {
        gender: "MALE",
        background: "",
        skin: "",
        eyewear: "none",
        mouth: "",
        clothing: "none",
        eyes: "",
        headgear: "none",
        accesories: "none",
      },
      uri: "",
    });
  const imageWidth = "25px";
  const imageHeight = "35px";

  const handleCreate = () => {
    characterObjectName.uri = characterObjectState.uri;
    if (potionToken === "") {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Please select Potion first",
        })
      );
    } else if (characterObjectName.childTraits.accesories === "") {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Please select Accesories",
        })
      );
    } else if (characterObjectName.childTraits.background === "") {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Please select background",
        })
      );
    } else if (characterObjectName.childTraits.clothing === "") {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Please select Clothes",
        })
      );
    } else if (characterObjectName.childTraits.eyes === "") {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Please select Eyes",
        })
      );
    } else if (characterObjectName.childTraits.eyewear === "") {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Please select Eye-wear",
        })
      );
    } else if (characterObjectName.childTraits.headgear === "") {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Please select Headgear",
        })
      );
    } else if (characterObjectName.childTraits.mouth === "") {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Please select Mouth",
        })
      );
    } else if (characterObjectName.childTraits.skin === "") {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Please select Skin",
        })
      );
    } else {
      try {
        const token = localStorage.getItem("token");
        socketio = io(API.socket_backend, {
          autoConnect: false,
          auth: { token },
        });

        // useDispatch(setSocketConnected());
        socketsReq(socketio);

        socketio.connect();
      } catch (e) {
        console.log(e, "error in socket");
      }
      createNFT();
    }
  };

  async function createNFT() {
    dispatch(setLoader(true));

    try {
      const response: any = await axiosRequestAuth(
        "post",
        API.cubApiURL,
        characterObjectName,
        undefined
      );
      console.log("data is here", response);
      setOpenModal(true);
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: response.data.message,
        })
      );
      dispatch(setLoader(false));
    } catch (err: any) {
      console.log("data is Error", err?.response.data.message);
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: err?.response.data.message,
        })
      );
      dispatch(setLoader(false));
    }
  }

  const checkBoxFunc1 = () => {
    setcheckBoxCheck1(true);
    setcheckBoxCheck2(false);

    const newCharacterObjectName = structuredClone(characterObjectName);
    const newCharacterObjectState = structuredClone(characterObjectState);

    newCharacterObjectName.childTraits.gender = "MALE";
    newCharacterObjectName.childTraits.background = "";
    newCharacterObjectName.childTraits.skin = "";
    newCharacterObjectName.childTraits.eyewear = "";
    newCharacterObjectName.childTraits.mouth = "";
    newCharacterObjectName.childTraits.eyes = "";
    newCharacterObjectName.childTraits.clothing = "";
    newCharacterObjectName.childTraits.headgear = "";
    newCharacterObjectName.childTraits.accesories = "";

    newCharacterObjectState.childTraits.gender = "MALE";
    newCharacterObjectState.childTraits.background = "";
    newCharacterObjectState.childTraits.skin = "";
    newCharacterObjectState.childTraits.eyewear = "";
    newCharacterObjectState.childTraits.mouth = "";
    newCharacterObjectState.childTraits.eyes = "";
    newCharacterObjectState.childTraits.clothing = "";
    newCharacterObjectState.childTraits.headgear = "";
    newCharacterObjectState.childTraits.accesories = "";

    setCharacterObjectName(newCharacterObjectName);

    setCharacterObjectState(newCharacterObjectState);
  };

  const checkBoxFunc2 = () => {
    setcheckBoxCheck1(false);
    setcheckBoxCheck2(true);
    const newCharacterObjectName = structuredClone(characterObjectName);
    const newCharacterObjectState = structuredClone(characterObjectState);

    newCharacterObjectName.childTraits.gender = "FEMALE";
    newCharacterObjectName.childTraits.background = "";
    newCharacterObjectName.childTraits.skin = "";
    newCharacterObjectName.childTraits.eyewear = "";
    newCharacterObjectName.childTraits.mouth = "";
    newCharacterObjectName.childTraits.eyes = "";
    newCharacterObjectName.childTraits.clothing = "";
    newCharacterObjectName.childTraits.headgear = "";
    newCharacterObjectName.childTraits.accesories = "";

    newCharacterObjectState.childTraits.gender = "FEMALE";
    newCharacterObjectState.childTraits.background = "";
    newCharacterObjectState.childTraits.skin = "";
    newCharacterObjectState.childTraits.eyewear = "";
    newCharacterObjectState.childTraits.mouth = "";
    newCharacterObjectState.childTraits.eyes = "";
    newCharacterObjectState.childTraits.clothing = "";
    newCharacterObjectState.childTraits.headgear = "";
    newCharacterObjectState.childTraits.accesories = "";
    setCharacterObjectName(newCharacterObjectName);
    setCharacterObjectState(newCharacterObjectState);
  };

  const handleChangeBackground = () => {
    setOpenBackground((prev) => !prev);
  };

  const handleChangeHeadgear = () => {
    setOpenHeadgear((prev) => !prev);
  };
  const handleChangeSkin = () => {
    setOpenSkin((prev) => !prev);
  };
  const handleChangeEyes = () => {
    setOpenEyes((prev) => !prev);
  };
  const handleChangeEyewear = () => {
    setOpenEyeswear((prev) => !prev);
  };
  const handleChangeMouth = () => {
    setOpenMouth((prev) => !prev);
  };
  const handleChangeClothes = () => {
    setOpenClothes((prev) => !prev);
  };

  const handleChangeAccessories = () => {
    setOpenAccessories((prev) => !prev);
  };

  const handleBackground = (item: any) => {
    const newCharacterObject = structuredClone(characterObjectState);
    newCharacterObject.childTraits.background = item.img;
    setCharacterObjectState(newCharacterObject);

    const newCharacterObjectName = structuredClone(characterObjectName);
    newCharacterObjectName.childTraits.background = item?.name;
    setCharacterObjectName(newCharacterObjectName);

    setActiveBackground(item.key);

    console.log(item, "mobile View");
  };

  const handleHeadgear = (item: any) => {
    const newCharacterObject = structuredClone(characterObjectState);
    newCharacterObject.childTraits.headgear = item.img;
    setCharacterObjectState(newCharacterObject);

    const newCharacterObjectName = structuredClone(characterObjectName);
    newCharacterObjectName.childTraits.headgear = item?.name;
    setCharacterObjectName(newCharacterObjectName);

    setActiveHeadGear(item.key);
  };
  const handleSkin = (item: any) => {
    const newCharacterObject = structuredClone(characterObjectState);
    newCharacterObject.childTraits.skin = item.img;
    setCharacterObjectState(newCharacterObject);

    const newCharacterObjectName = structuredClone(characterObjectName);
    newCharacterObjectName.childTraits.skin = item?.name;
    setCharacterObjectName(newCharacterObjectName);

    setActiveSkin(item.key);
  };

  const handleClothes = (item: any) => {
    const newCharacterObject = structuredClone(characterObjectState);
    newCharacterObject.childTraits.clothing = item.img;
    setCharacterObjectState(newCharacterObject);

    const newCharacterObjectName = structuredClone(characterObjectName);
    newCharacterObjectName.childTraits.clothing = item?.name;
    setCharacterObjectName(newCharacterObjectName);

    setActiveClothes(item.key);
  };

  const handleAccessories = (item: any) => {
    const newCharacterObject = structuredClone(characterObjectState);
    newCharacterObject.childTraits.accesories = item.img;
    setCharacterObjectState(newCharacterObject);

    const newCharacterObjectName = structuredClone(characterObjectName);
    newCharacterObjectName.childTraits.accesories = item?.name;
    setCharacterObjectName(newCharacterObjectName);

    setActiveAccessories(item.key);
  };

  const handleEyesWear = (item: any) => {
    const newCharacterObject = structuredClone(characterObjectState);
    newCharacterObject.childTraits.eyewear = item.img;
    setCharacterObjectState(newCharacterObject);

    const newCharacterObjectName = structuredClone(characterObjectName);
    newCharacterObjectName.childTraits.eyewear = item?.name;
    setCharacterObjectName(newCharacterObjectName);
    setActiveEyesWear(item.key);
  };

  const handleEyes = (item: any) => {
    const newCharacterObject = structuredClone(characterObjectState);
    newCharacterObject.childTraits.eyes = item.img;
    setCharacterObjectState(newCharacterObject);

    const newCharacterObjectName = structuredClone(characterObjectName);
    newCharacterObjectName.childTraits.eyes = item?.name;
    setCharacterObjectName(newCharacterObjectName);
    setActiveEyes(item.key);
  };
  const handleMouth = (item: any) => {
    const newCharacterObject = structuredClone(characterObjectState);
    newCharacterObject.childTraits.mouth = item.img;
    setCharacterObjectState(newCharacterObject);

    const newCharacterObjectName = structuredClone(characterObjectName);
    newCharacterObjectName.childTraits.mouth = item?.name;
    setCharacterObjectName(newCharacterObjectName);
    setActiveMouth(item.key);
  };

  const socketsReq = (socket: any) => {
    dispatch(connectSocket(socket));

    socket.on("connect", () => {
      console.log("socket Connected");
    });

    socket.on("disconnect", (reason: any) => {
      console.log("socket is disconnected", reason);
    });

    socket.on("burn/signed", (data: any) => {
      setMintCheck(true);
      dispatch(setLoader(true));
      console.log(" burn", data?.didUserSign);
      if (data?.didUserSign === false) {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Burn request rejected from Xumm",
          })
        );
        setOpenModal(false);
      } else {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Burn request accepted from Xumm",
          })
        );
      }
    });
    socket.on("mint/signed", (data: any) => {
      setOpenModal(false);

      console.log(" sign", data?.didUserSign);
      if (data?.didUserSign === false) {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Mint request rejected from Xumm",
          })
        );
      } else {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: " Mint request accepted from Xumm",
          })
        );
        navigate("/transaction-history");
      }
    });
    socket.on("queue/progress", (data: any) => {
      setMintCheck(false);
      dispatch(setCurentProcessNumber(data.currentlyProcessing));
      console.log(" Queue Processing", data);
    });
    socket.on("queue/added", (data: any) => {
      dispatch(setYorProcessNumber(data.newMintQueueNumber));
      dispatch(setCurentProcessNumber(data.currentlyProcessing));

      console.log(" Queue Added", data);
    });
    socket.on("mint/completed", (data: any) => {
      console.log(" Queue completed", data);
      dispatch(
        setSnackbar({
          snackbarOpen: false,
          snackbarType: "info",
          snackbarMessage: ``,
        })
      );
      if (data.didFail) {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage:
              "Something went wrong, please contact support@luxlionsnft.com",
          })
        );
      } else {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Cub Minted successfully!",
          })
        );
      }
      dispatch(setLoader(false));
      dispatch(connectSocket({}));
    });
  };
  return (
    <div>
      <img
        src={BreedingBackground}
        alt=""
        style={{ width: "100%", height: "100vh" }}
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          width: "100%",
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Box
              sx={{
                mt: 4,
                justifyContent: "space-between",
                display: "flex",
                px: 4,
              }}
            >
              <Box>
                <img
                  src={`${Logo}`}
                  alt=""
                  width="60%"
                  onClick={() => navigate("/breeding")}
                />
              </Box>
              <LogoutComp />
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent="center"
          display="flex"
          textAlign="center"
        >
          <Grid
            sm={12}
            md={12}
            sx={{ px: 2, mb: -2, display: "flex", justifyContent: "center" }}
          >
            <Grid sm={5}>
              <Box
                sx={{
                  mx: 0,
                  background: "rgba(63, 63, 63, 0.9)",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  height: "4vh",
                  mt: 4,
                  textAlign: "center",
                  width: 300,
                }}
              >
                <Typography
                  sx={{
                    color: "white",
                    fontSize: 18,
                    fontWeight: 600,
                    textShadow: "0px 4px 3px rgba(109, 248, 254, 0.5);",
                  }}
                >
                  CHOOSE YOUR TRAITS
                </Typography>
              </Box>
              <Canvas charObj={characterObjectState} />
            </Grid>
          </Grid>
        </Grid>
        {/* ///////////////////////  1st  ///////////////// */}
        <Box sx={{ mt: 4, justifyContent: "space-between", display: "flex" }}>
          <Button
            variant="contained"
            sx={{
              color: "white",
              background: "#003548",

              // border: "1px solid #ffff",
              "&:hover": {
                background: "rgba(109, 258, 264, 0.6)",
                color: "black",
              },
              borderRadius: "0px",
              p: 0.5,
            }}
            onClick={handleChangeBackground}
          >
            <img
              src={`${BackgroundIcon}`}
              style={{ width: "40px", height: imageHeight }}
            ></img>
          </Button>
          <Box
            textAlign="right"
            // direction="row"
            justifyContent="space-between"
            sx={{
              mt: 2,

              display: "flex",
              flexDirection: "row",
            }}
            className={styleGrids.box}
          >
            <FormControlLabel
              value="top"
              sx={{ color: "white", ml: 1 }}
              control={
                <Checkbox
                  checked={checkBoxCheck1}
                  onClick={checkBoxFunc1}
                  sx={{
                    color: "white",

                    "&.Mui-checked": {
                      color: "#6DF8FE",
                    },
                  }}
                  size="small"
                />
              }
              label={
                <Typography sx={{ fontSize: 18, fontFamily: "jedi" }}>
                  MALE
                </Typography>
              }
              labelPlacement="end"
            />
            <FormControlLabel
              value="top"
              sx={{ color: "white" }}
              control={
                <Checkbox
                  checked={checkBoxCheck2}
                  onClick={checkBoxFunc2}
                  sx={{
                    color: "white",

                    "&.Mui-checked": {
                      color: "#6DF8FE",
                    },
                  }}
                  size="small"
                />
              }
              label={
                <Typography sx={{ fontSize: 18, fontFamily: "jedi" }}>
                  FEMALE
                </Typography>
              }
              labelPlacement="end"
            />
          </Box>
          <Button
            variant="contained"
            sx={{
              color: "white",
              background: "#003548",

              // border: "1px solid #ffff",
              "&:hover": {
                background: "rgba(109, 258, 264, 0.6)",
                color: "black",
              },
              borderRadius: "0px",
              p: 0.5,
            }}
            onClick={handleChangeHeadgear}
          >
            <img
              src={`${HeadIcon}`}
              style={{ width: "40px", height: imageHeight }}
            ></img>
          </Button>
        </Box>
        {/* ///////////////////////  2nd  ///////////////// */}
        <Box sx={{ mt: 3, justifyContent: "space-between", display: "flex" }}>
          <Button
            variant="contained"
            sx={{
              color: "white",
              background: "#003548",

              // border: "1px solid #ffff",
              "&:hover": {
                background: "rgba(109, 258, 264, 0.6)",
                color: "black",
              },
              borderRadius: "0px",
              p: 0.5,
            }}
            onClick={handleChangeSkin}
          >
            <img
              src={`${BodyIcon}`}
              style={{ width: imageWidth, height: imageHeight }}
            ></img>
          </Button>
          <Button
            variant="contained"
            sx={{
              color: "white",
              background: "rgba(70, 70, 70, 0.8)",
              border: "1px solid #ffff",
              "&:hover": {
                background: "#36454F",
              },
            }}
            onClick={handleCreate}
          >
            CREATE
          </Button>
          <Button
            variant="contained"
            sx={{
              color: "white",
              background: "#003548",

              // border: "1px solid #ffff",
              "&:hover": {
                background: "rgba(109, 258, 264, 0.6)",
                color: "black",
              },
              borderRadius: "0px",
              p: 0.5,
            }}
            onClick={handleChangeAccessories}
          >
            <img
              src={`${AccessotiesIcon}`}
              style={{ width: "45px", height: "35px" }}
            />
          </Button>
        </Box>
        {/* ///////////////////////  3rd ///////////////// */}
        <Box sx={{ mt: 3, justifyContent: "space-between", display: "flex" }}>
          <Button
            variant="contained"
            sx={{
              color: "white",
              background: "#003548",

              // border: "1px solid #ffff",
              "&:hover": {
                background: "rgba(109, 258, 264, 0.6)",
                color: "black",
              },
              borderRadius: "0px",
              p: 0.5,
            }}
            onClick={handleChangeClothes}
          >
            <img
              src={`${ClothesIcon}`}
              style={{ width: imageWidth, height: imageHeight }}
            ></img>
          </Button>
          <Button
            variant="contained"
            sx={{
              color: "white",
              background: "#003548",

              // border: "1px solid #ffff",
              "&:hover": {
                background: "rgba(109, 258, 264, 0.6)",
                color: "black",
              },
              borderRadius: "0px",
              p: 0.5,
            }}
            onClick={handleChangeEyes}
          >
            <img
              src={`${EyeIcon}`}
              style={{ width: "35px", height: "25px" }}
            ></img>
          </Button>
        </Box>
        {/* ///////////////////////  4th ///////////////// */}
        <Box sx={{ mt: 3, justifyContent: "space-between", display: "flex" }}>
          <Button
            variant="contained"
            sx={{
              color: "white",
              background: "#003548",

              // border: "1px solid #ffff",
              "&:hover": {
                background: "rgba(109, 258, 264, 0.6)",
                color: "black",
              },
              borderRadius: "0px",
              p: 0.5,
            }}
            onClick={handleChangeMouth}
          >
            <img
              src={`${MouthIcon}`}
              style={{ width: imageWidth, height: imageHeight }}
            ></img>
          </Button>
          <Box>
            <Button
              variant="contained"
              sx={{
                color: "white",
                background: "#003548",

                // border: "1px solid #ffff",
                "&:hover": {
                  background: "rgba(109, 258, 264, 0.6)",
                  color: "black",
                },
                borderRadius: "0px",
                p: 0.5,
              }}
              onClick={handleChangeEyewear}
            >
              <img
                src={`${GlassesIcon}`}
                style={{ width: "45px", height: "35px" }}
              ></img>
            </Button>
          </Box>
        </Box>
        {/* ///////////////////////  5th ///////////////// */}
        <Box sx={{ mt: 1 }}>
          {/* <Box
            sx={{
              background: "rgba(217, 217, 217, 0.8);",
              borderRadius: "15px",

              height: 60,
              zIndex: 99,
              mt: 1,
              mx: 5,
            }}
          >
            <PotionCarousel />
          </Box> */}
          <Box></Box>
        </Box>
        <Backdrop
          //   sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBackground}
          onClick={handleChangeBackground}
        >
          <Slide
            direction="right"
            in={openBackground}
            container={containerRef.current}
            timeout={{ enter: 500 }}
          >
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{
                // background: " rgba(109, 248, 254, 0.6)",
                borderRadius: "20px",
                minHeight: "70vh",
                maxHeight: "70vh",
                px: 2,
                ml: 4,
              }}
            >
              <Grid item sm={12} md={12} lg={12}>
                <Box
                  sx={{
                    // background: " rgba(109, 248, 254, 0.6)",
                    borderRadius: "10px",
                    display: "flex",
                    p: 2,
                    m: 0,
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    container
                    spacing={{ sm: 2 }}
                    columns={{ sm: 8 }}
                    className={"custom-scroll"}
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      background: "rgba(63, 63, 63, 0.9)",
                      borderRadius: "10px",
                      minHeight: "70vh",
                      maxHeight: "70vh",
                      p: 2,
                      // pb: "15px",
                      border: "solid 8px",
                      borderColor: "rgba(63, 63, 63, 1)",
                      overflowY: "scroll",
                      //   px: 2,
                    }}
                  >
                    {backgroundImg.map((item, index) => (
                      <Grid item sm={4} key={item.key} sx={{ py: 2, px: 1 }}>
                        {activeBackground == item.key ? (
                          <Box
                            width="25vw"
                            height="10vh"
                            className={styleGrids.grid}
                            key={item.key}
                            onClick={() => handleBackground(item)}
                            sx={{ border: "2px solid #6DF8FE" }}
                          >
                            <img
                              src={item.img}
                              alt=""
                              style={{ width: "25vw", height: "10vh" }}
                              key={index}
                            />
                          </Box>
                        ) : (
                          <Box
                            width="25vw"
                            height="10vh"
                            className={styleGrids.grid}
                            key={item.key}
                            onClick={() => handleBackground(item)}
                          >
                            <img
                              src={item.img}
                              alt=""
                              style={{ width: "25vw", height: "10vh" }}
                              key={index}
                            />
                          </Box>
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>{" "}
            </Grid>
          </Slide>
        </Backdrop>
        {/* ////////////////////  background ///////////////////////////////////*/}
        <Backdrop
          //   sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openHeadgear}
          onClick={handleChangeHeadgear}
        >
          <Slide
            direction="left"
            in={openHeadgear}
            container={containerRef.current}
            timeout={{ enter: 500 }}
          >
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{
                // background: " rgba(109, 248, 254, 0.6)",
                borderRadius: "20px",
                minHeight: "70vh",
                maxHeight: "70vh",
                px: 2,
                ml: 4,
              }}
            >
              <Grid item sm={12} md={12} lg={12}>
                <Box
                  sx={{
                    // background: " rgba(109, 248, 254, 0.6)",
                    borderRadius: "10px",
                    display: "flex",
                    p: 2,
                    m: 0,
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    container
                    spacing={{ sm: 2 }}
                    columns={{ sm: 8 }}
                    className={"custom-scroll"}
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      background: "rgba(63, 63, 63, 0.9)",
                      borderRadius: "10px",
                      minHeight: "70vh",
                      maxHeight: "70vh",
                      p: 2,
                      // pb: "15px",
                      border: "solid 8px",
                      borderColor: "rgba(63, 63, 63, 1)",
                      overflowY: "scroll",
                      //   px: 2,
                    }}
                  >
                    {HeadGearImg.map((item, index) => (
                      <Grid item sm={4} key={item.key} sx={{ py: 2, px: 1 }}>
                        {item.key === 1 ? (
                          <>
                            <Box
                              width="25vw"
                              height="10vh"
                              className={styleGrids.grid}
                              key={item.key}
                              onClick={() => handleHeadgear(item)}
                              // sx={{ border: "2px solid #6DF8FE" }}
                            >
                              <img
                                src={crossImg[0].img}
                                alt=""
                                style={{ width: "25vw", height: "10vh" }}
                                key={index}
                              />
                            </Box>
                          </>
                        ) : activeHeadGear == item.key ? (
                          <Box
                            width="25vw"
                            height="10vh"
                            className={styleGrids.grid}
                            key={item.key}
                            onClick={() => handleHeadgear(item)}
                            sx={{ border: "2px solid #6DF8FE" }}
                          >
                            <img
                              src={item.img}
                              alt=""
                              style={{ width: "25vw", height: "10vh" }}
                              key={index}
                            />
                          </Box>
                        ) : (
                          <Box
                            width="25vw"
                            height="10vh"
                            className={styleGrids.grid}
                            key={item.key}
                            onClick={() => handleHeadgear(item)}
                          >
                            <img
                              src={item.img}
                              alt=""
                              style={{ width: "25vw", height: "10vh" }}
                              key={index}
                            />
                          </Box>
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>{" "}
            </Grid>
          </Slide>
        </Backdrop>

        {/* //////////////////////  Accessories ///////////////////////////////////*/}
        <Backdrop
          //   sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openAccessories}
          onClick={handleChangeAccessories}
        >
          <Slide
            direction="left"
            in={openAccessories}
            container={containerRef.current}
            timeout={{ enter: 500 }}
          >
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{
                // background: " rgba(109, 248, 254, 0.6)",
                borderRadius: "20px",
                minHeight: "70vh",
                maxHeight: "70vh",
                px: 2,
                ml: 4,
              }}
            >
              <Grid item sm={12} md={12} lg={12}>
                <Box
                  sx={{
                    // background: " rgba(109, 248, 254, 0.6)",
                    borderRadius: "10px",
                    display: "flex",
                    p: 2,
                    m: 0,
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    container
                    spacing={{ sm: 2 }}
                    columns={{ sm: 8 }}
                    className={"custom-scroll"}
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      background: "rgba(63, 63, 63, 0.9)",
                      borderRadius: "10px",
                      minHeight: "70vh",
                      maxHeight: "70vh",
                      p: 2,
                      // pb: "15px",
                      border: "solid 8px",
                      borderColor: "rgba(63, 63, 63, 1)",
                      overflowY: "scroll",
                      //   px: 2,
                    }}
                  >
                    {AccessoriesImg.map((item, index) => (
                      <Grid item sm={4} key={item.key} sx={{ py: 2, px: 1 }}>
                        {item.key === 1 ? (
                          <>
                            <Box
                              width="25vw"
                              height="10vh"
                              className={styleGrids.grid}
                              key={item.key}
                              onClick={() => handleAccessories(item)}
                              // sx={{ border: "2px solid #6DF8FE" }}
                            >
                              <img
                                src={crossImg[0].img}
                                alt=""
                                style={{ width: "25vw", height: "10vh" }}
                                key={index}
                              />
                            </Box>
                          </>
                        ) : activeAccessories == item.key ? (
                          <Box
                            width="25vw"
                            height="10vh"
                            className={styleGrids.grid}
                            key={item.key}
                            onClick={() => handleAccessories(item)}
                            sx={{ border: "2px solid #6DF8FE" }}
                          >
                            <img
                              src={item.img}
                              alt=""
                              style={{ width: "25vw", height: "10vh" }}
                              key={index}
                            />
                          </Box>
                        ) : (
                          <Box
                            width="25vw"
                            height="10vh"
                            className={styleGrids.grid}
                            key={item.key}
                            onClick={() => handleAccessories(item)}
                          >
                            <img
                              src={item.img}
                              alt=""
                              style={{ width: "25vw", height: "10vh" }}
                              key={index}
                            />
                          </Box>
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>{" "}
            </Grid>
          </Slide>
        </Backdrop>
        {/* ////////////////////  Skin ///////////////////////////////////*/}
        <Backdrop
          //   sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openSkin}
          onClick={handleChangeSkin}
        >
          <Slide
            direction="right"
            in={openSkin}
            container={containerRef.current}
            timeout={{ enter: 500 }}
          >
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{
                // background: " rgba(109, 248, 254, 0.6)",
                borderRadius: "20px",
                minHeight: "70vh",
                maxHeight: "70vh",
                px: 2,
                ml: 4,
              }}
            >
              <Grid item sm={12} md={12} lg={12}>
                <Box
                  sx={{
                    // background: " rgba(109, 248, 254, 0.6)",
                    borderRadius: "10px",
                    display: "flex",
                    p: 2,
                    m: 0,
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    container
                    spacing={{ sm: 2 }}
                    columns={{ sm: 8 }}
                    className={"custom-scroll"}
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      background: "rgba(63, 63, 63, 0.9)",
                      borderRadius: "10px",
                      minHeight: "70vh",
                      maxHeight: "70vh",
                      p: 2,
                      // pb: "15px",
                      border: "solid 8px",
                      borderColor: "rgba(63, 63, 63, 1)",
                      overflowY: "scroll",
                      //   px: 2,
                    }}
                  >
                    {skinImg.map((item, index) => (
                      <Grid item sm={4} key={item.key} sx={{ py: 2, px: 1 }}>
                        {activeSkin == item.key ? (
                          <Box
                            width="25vw"
                            height="10vh"
                            className={styleGrids.grid}
                            key={item.key}
                            onClick={() => handleSkin(item)}
                            sx={{ border: "2px solid #6DF8FE" }}
                          >
                            <img
                              src={item.img}
                              alt=""
                              style={{ width: "25vw", height: "10vh" }}
                              key={index}
                            />
                          </Box>
                        ) : (
                          <Box
                            width="25vw"
                            height="10vh"
                            className={styleGrids.grid}
                            key={item.key}
                            onClick={() => handleSkin(item)}
                          >
                            <img
                              src={item.img}
                              alt=""
                              style={{ width: "25vw", height: "10vh" }}
                              key={index}
                            />
                          </Box>
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>{" "}
            </Grid>
          </Slide>
        </Backdrop>
        {/* ////////////////////   Clothes ///////////////////////////////////*/}
        <Backdrop
          //   sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openClothes}
          onClick={handleChangeClothes}
        >
          <Slide
            direction="right"
            in={openClothes}
            container={containerRef.current}
            timeout={{ enter: 500 }}
          >
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{
                // background: " rgba(109, 248, 254, 0.6)",
                borderRadius: "20px",
                minHeight: "70vh",
                maxHeight: "70vh",
                px: 2,
                ml: 4,
              }}
            >
              <Grid item sm={12} md={12} lg={12}>
                <Box
                  sx={{
                    // background: " rgba(109, 248, 254, 0.6)",
                    borderRadius: "10px",
                    display: "flex",
                    p: 2,
                    m: 0,
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    container
                    spacing={{ sm: 2 }}
                    columns={{ sm: 8 }}
                    className={"custom-scroll"}
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      background: "rgba(63, 63, 63, 0.9)",
                      borderRadius: "10px",
                      minHeight: "70vh",
                      maxHeight: "70vh",
                      p: 2,
                      // pb: "15px",
                      border: "solid 8px",
                      borderColor: "rgba(63, 63, 63, 1)",
                      overflowY: "scroll",
                      //   px: 2,
                    }}
                  >
                    {clothsImg.map((item, index) => (
                      <Grid item sm={4} key={item.key} sx={{ py: 2, px: 1 }}>
                        {item.key === 1 ? (
                          <>
                            <Box
                              width="25vw"
                              height="10vh"
                              className={styleGrids.grid}
                              key={item.key}
                              onClick={() => handleClothes(item)}
                              // sx={{ border: "2px solid #6DF8FE" }}
                            >
                              <img
                                src={crossImg[0].img}
                                alt=""
                                style={{ width: "25vw", height: "10vh" }}
                                key={index}
                              />
                            </Box>
                          </>
                        ) : activeClothes == item.key ? (
                          <Box
                            width="25vw"
                            height="10vh"
                            className={styleGrids.grid}
                            key={item.key}
                            onClick={() => handleClothes(item)}
                            sx={{ border: "2px solid #6DF8FE" }}
                          >
                            <img
                              src={item.img}
                              alt=""
                              style={{ width: "25vw", height: "10vh" }}
                              key={index}
                            />
                          </Box>
                        ) : (
                          <Box
                            width="25vw"
                            height="10vh"
                            className={styleGrids.grid}
                            key={item.key}
                            onClick={() => handleClothes(item)}
                          >
                            <img
                              src={item.img}
                              alt=""
                              style={{ width: "25vw", height: "10vh" }}
                              key={index}
                            />
                          </Box>
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>{" "}
            </Grid>
          </Slide>
        </Backdrop>

        {/* ////////////////////   Eyes  ///////////////////////////////////*/}

        <Backdrop
          //   sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openEyes}
          onClick={handleChangeEyes}
        >
          <Slide
            direction="left"
            in={openEyes}
            container={containerRef.current}
            timeout={{ enter: 500 }}
          >
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{
                // background: " rgba(109, 248, 254, 0.6)",
                borderRadius: "20px",
                minHeight: "70vh",
                maxHeight: "70vh",
                px: 2,
                ml: 4,
              }}
            >
              <Grid item sm={12} md={12} lg={12}>
                <Box
                  sx={{
                    // background: " rgba(109, 248, 254, 0.6)",
                    borderRadius: "10px",
                    display: "flex",
                    p: 2,
                    m: 0,
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    container
                    spacing={{ sm: 2 }}
                    columns={{ sm: 8 }}
                    className={"custom-scroll"}
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      background: "rgba(63, 63, 63, 0.9)",
                      borderRadius: "10px",
                      minHeight: "70vh",
                      maxHeight: "70vh",
                      p: 2,
                      // pb: "15px",
                      border: "solid 8px",
                      borderColor: "rgba(63, 63, 63, 1)",
                      overflowY: "scroll",
                      //   px: 2,
                    }}
                  >
                    {maleEyesImg.map((item, index) => (
                      <Grid item sm={4} key={item.key} sx={{ py: 2, px: 1 }}>
                        {activeEyes == item.key ? (
                          <Box
                            width="25vw"
                            height="10vh"
                            className={styleGrids.grid}
                            key={item.key}
                            onClick={() => handleEyes(item)}
                            sx={{ border: "2px solid #6DF8FE" }}
                          >
                            <img
                              src={item.img}
                              alt=""
                              style={{ width: "25vw", height: "10vh" }}
                              key={index}
                            />
                          </Box>
                        ) : (
                          <Box
                            width="25vw"
                            height="10vh"
                            className={styleGrids.grid}
                            key={item.key}
                            onClick={() => handleEyes(item)}
                          >
                            <img
                              src={item.img}
                              alt=""
                              style={{ width: "25vw", height: "10vh" }}
                              key={index}
                            />
                          </Box>
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>{" "}
            </Grid>
          </Slide>
        </Backdrop>

        {/* ////////////////////   Mouth  ///////////////////////////////////*/}

        <Backdrop
          //   sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openMouth}
          onClick={handleChangeMouth}
        >
          <Slide
            direction="right"
            in={openMouth}
            container={containerRef.current}
            timeout={{ enter: 500 }}
          >
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{
                // background: " rgba(109, 248, 254, 0.6)",
                borderRadius: "20px",
                minHeight: "70vh",
                maxHeight: "70vh",
                px: 2,
                ml: 4,
              }}
            >
              <Grid item sm={12} md={12} lg={12}>
                <Box
                  sx={{
                    // background: " rgba(109, 248, 254, 0.6)",
                    borderRadius: "10px",
                    display: "flex",
                    p: 2,
                    m: 0,
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    container
                    spacing={{ sm: 2 }}
                    columns={{ sm: 8 }}
                    className={"custom-scroll"}
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      background: "rgba(63, 63, 63, 0.9)",
                      borderRadius: "10px",
                      minHeight: "70vh",
                      maxHeight: "70vh",
                      p: 2,
                      // pb: "15px",
                      border: "solid 8px",
                      borderColor: "rgba(63, 63, 63, 1)",
                      overflowY: "scroll",
                      //   px: 2,
                    }}
                  >
                    {" "}
                    {maleMouthImg.map((item, index) => (
                      <Grid item sm={4} key={item.key} sx={{ py: 2, px: 1 }}>
                        {activeEyes == item.key ? (
                          <Box
                            width="25vw"
                            height="10vh"
                            className={styleGrids.grid}
                            key={item.key}
                            onClick={() => handleMouth(item)}
                            sx={{ border: "2px solid #6DF8FE" }}
                          >
                            <img
                              src={item.img}
                              alt=""
                              style={{ width: "25vw", height: "10vh" }}
                              key={index}
                            />
                          </Box>
                        ) : (
                          <Box
                            width="25vw"
                            height="10vh"
                            className={styleGrids.grid}
                            key={item.key}
                            onClick={() => handleMouth(item)}
                          >
                            <img
                              src={item.img}
                              alt=""
                              style={{ width: "25vw", height: "10vh" }}
                              key={index}
                            />
                          </Box>
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>{" "}
            </Grid>
          </Slide>
        </Backdrop>

        {/* ////////////////////   Eye Wear  ///////////////////////////////////*/}

        <Backdrop
          //   sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openEyeswear}
          onClick={handleChangeEyewear}
        >
          <Slide
            direction="left"
            in={openEyeswear}
            container={containerRef.current}
            timeout={{ enter: 500 }}
          >
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{
                // background: " rgba(109, 248, 254, 0.6)",
                borderRadius: "20px",
                minHeight: "70vh",
                maxHeight: "70vh",
                px: 2,
                ml: 4,
              }}
            >
              <Grid item sm={12} md={12} lg={12}>
                <Box
                  sx={{
                    // background: " rgba(109, 248, 254, 0.6)",
                    borderRadius: "10px",
                    display: "flex",
                    p: 2,
                    m: 0,
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    container
                    spacing={{ sm: 2 }}
                    columns={{ sm: 8 }}
                    className={"custom-scroll"}
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      background: "rgba(63, 63, 63, 0.9)",
                      borderRadius: "10px",
                      minHeight: "70vh",
                      maxHeight: "70vh",
                      p: 2,
                      // pb: "15px",
                      border: "solid 8px",
                      borderColor: "rgba(63, 63, 63, 1)",
                      overflowY: "scroll",
                      //   px: 2,
                    }}
                  >
                    {EyeWearImg.map((item, index) => (
                      <Grid item sm={4} key={item.key} sx={{ py: 2, px: 1 }}>
                        {item.key === 1 ? (
                          <>
                            <Box
                              width="25vw"
                              height="10vh"
                              className={styleGrids.grid}
                              key={item.key}
                              onClick={() => handleEyesWear(item)}
                              // sx={{ border: "2px solid #6DF8FE" }}
                            >
                              <img
                                src={crossImg[0].img}
                                alt=""
                                style={{ width: "25vw", height: "10vh" }}
                                key={index}
                              />
                            </Box>
                          </>
                        ) : activeEyesWear == item.key ? (
                          <Box
                            width="25vw"
                            height="10vh"
                            className={styleGrids.grid}
                            key={item.key}
                            onClick={() => handleEyesWear(item)}
                            sx={{ border: "2px solid #6DF8FE" }}
                          >
                            <img
                              src={item.img}
                              alt=""
                              style={{ width: "25vw", height: "10vh" }}
                              key={index}
                            />
                          </Box>
                        ) : (
                          <Box
                            width="25vw"
                            height="10vh"
                            className={styleGrids.grid}
                            key={item.key}
                            onClick={() => handleEyesWear(item)}
                          >
                            <img
                              src={item.img}
                              alt=""
                              style={{ width: "25vw", height: "10vh" }}
                              key={index}
                            />
                          </Box>
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>{" "}
            </Grid>
          </Slide>
        </Backdrop>
      </div>
      <>
        <Modal open={modalOpen}>
          <Box sx={style}>
            {mintcheck === true ? (
              <Typography
                id="modal-modal-title"
                variant="h5"
                component="h2"
                sx={{ fontFamily: "Jedi", fontWeight: 600 }}
              >
                Mint request generated. Awaiting xumm approval!
              </Typography>
            ) : (
              <Typography
                id="modal-modal-title"
                variant="h5"
                component="h2"
                sx={{ fontFamily: "Jedi", fontWeight: 600 }}
              >
                Burn request generated. Awaiting xumm approval!
              </Typography>
            )}
          </Box>
        </Modal>
      </>
    </div>
  );
};

export default TransitionGrid;
export interface characterObjectI {
  issuer: string;
  attributes: {
    background: string;
    skin: string;
    eyewear: string;
    mouth: string;
    clothing: string;
    eyes: string;
    headgear: string;
    accesories: string;
  };
}
