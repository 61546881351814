import React, { useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import TabPanel from "../../TabPanels/TabPanels";
import { Box, Grid, Tab, Tabs, TabScrollButton } from "@mui/material";
import { breedRequestBody } from "../DesginGrids";
import styleGrids from "./DesignGridsPanels.module.css";

import Accesories1 from "../../../assets/DesignAssets/Accessories/transparent.png";
import Accesories2 from "../../../assets/DesignAssets/Accessories/Accessories2.png";
import Accesories3 from "../../../assets/DesignAssets/Accessories/Accessories3.png";
import Accesories4 from "../../../assets/DesignAssets/Accessories/Accessories4.png";
import Accesories5 from "../../../assets/DesignAssets/Accessories/Accessories5.png";
import Accesories6 from "../../../assets/DesignAssets/Accessories/Accessories6.png";
import Accesories7 from "../../../assets/DesignAssets/Accessories/Accessories7.png";
import Accesories8 from "../../../assets/DesignAssets/Accessories/Accessories8.png";
import Accesories9 from "../../../assets/DesignAssets/Accessories/Accessories9.png";
import Accesories10 from "../../../assets/DesignAssets/Accessories/Accessories10.png";
import Accesories11 from "../../../assets/DesignAssets/Accessories/Accessories11.png";
import Accesories12 from "../../../assets/DesignAssets/Accessories/Accessories11.png";

import eyeWear1 from "../../../assets/DesignAssets/EyeWear/transparent.png";
import eyeWear2 from "../../../assets/DesignAssets/EyeWear/EYEWEAR2.png";
import eyeWear3 from "../../../assets/DesignAssets/EyeWear/EYEWEAR3.png";
import eyeWear4 from "../../../assets/DesignAssets/EyeWear/EYEWEAR4.png";
import eyeWear5 from "../../../assets/DesignAssets/EyeWear/EYEWEAR5.png";
import eyeWear6 from "../../../assets/DesignAssets/EyeWear/EYEWEAR6.png";
import eyeWear7 from "../../../assets/DesignAssets/EyeWear/EYEWEAR7.png";
import eyeWear8 from "../../../assets/DesignAssets/EyeWear/EYEWEAR8.png";
import eyeWear9 from "../../../assets/DesignAssets/EyeWear/EYEWEAR9.png";
import eyeWear10 from "../../../assets/DesignAssets/EyeWear/EYEWEAR10.png";
import eyeWear11 from "../../../assets/DesignAssets/EyeWear/EYEWEAR11.png";
import eyeWear12 from "../../../assets/DesignAssets/EyeWear/EYEWEAR12.png";
import eyeWear13 from "../../../assets/DesignAssets/EyeWear/EYEWEAR13.png";
import eyeWear14 from "../../../assets/DesignAssets/EyeWear/EYEWEAR14.png";
import eyeWear15 from "../../../assets/DesignAssets/EyeWear/EYEWEAR1.png";

import mouth1 from "../../../assets/DesignAssets/MaleMouth/MOUTH1.png";
import mouth2 from "../../../assets/DesignAssets/MaleMouth/MOUTH2.png";
import mouth3 from "../../../assets/DesignAssets/MaleMouth/MOUTH3.png";
import mouth4 from "../../../assets/DesignAssets/MaleMouth/MOUTH4.png";
import mouth5 from "../../../assets/DesignAssets/MaleMouth/MOUTH5.png";
import mouth6 from "../../../assets/DesignAssets/MaleMouth/MOUTH6.png";
import mouth7 from "../../../assets/DesignAssets/MaleMouth/MOUTH7.png";
import mouth8 from "../../../assets/DesignAssets/MaleMouth/MOUTH8.png";
import mouth9 from "../../../assets/DesignAssets/MaleMouth/MOUTH9.png";

import mouth10 from "../../../assets/DesignAssets/MaleMouth/MOUTH10.png";
import mouth11 from "../../../assets/DesignAssets/MaleMouth/MOUTH11.png";
import mouth12 from "../../../assets/DesignAssets/MaleMouth/MOUTH12.png";
import mouth13 from "../../../assets/DesignAssets/MaleMouth/MOUTH13.png";
import mouth14 from "../../../assets/DesignAssets/MaleMouth/MOUTH14.png";
import mouth15 from "../../../assets/DesignAssets/MaleMouth/MOUTH15.png";

/////////// Female eyes//////////////////////////////////////
import eyes1 from "../../../assets/DesignAssets/maleEyes/EYE1.png";
import eyes2 from "../../../assets/DesignAssets/maleEyes/EYE2.png";
import eyes3 from "../../../assets/DesignAssets/maleEyes/EYE3.png";
import eyes4 from "../../../assets/DesignAssets/maleEyes/EYE4.png";
import eyes5 from "../../../assets/DesignAssets/maleEyes/EYE5.png";
import eyes6 from "../../../assets/DesignAssets/maleEyes/EYE6.png";
import eyes7 from "../../../assets/DesignAssets/maleEyes/EYE7.png";
import eyes8 from "../../../assets/DesignAssets/maleEyes/EYE8.png";
import eyes9 from "../../../assets/DesignAssets/maleEyes/EYE9.png";

////////////////////// male eyes//////////////////////////////////////
import eyes10 from "../../../assets/DesignAssets/maleEyes/EYE10.png";
import eyes11 from "../../../assets/DesignAssets/maleEyes/EYE11.png";
import eyes12 from "../../../assets/DesignAssets/maleEyes/EYE12.png";
import eyes13 from "../../../assets/DesignAssets/maleEyes/EYE13.png";
import eyes14 from "../../../assets/DesignAssets/maleEyes/EYE14.png";
import eyes15 from "../../../assets/DesignAssets/maleEyes/EYE15.png";
import eyes16 from "../../../assets/DesignAssets/maleEyes/EYE16.png";
import eyes17 from "../../../assets/DesignAssets/maleEyes/EYE17.png";
import eyes18 from "../../../assets/DesignAssets/maleEyes/EYE18.png";
import eyes19 from "../../../assets/DesignAssets/maleEyes/EYE19.png";
import eyes20 from "../../../assets/DesignAssets/maleEyes/EYE20.png";
import Cross from "../../../assets/cross.png";

const AccessoriesImg = [
  { key: 1, img: Accesories1, name: "none" },
  { key: 2, img: Accesories2, name: "Accesories2" },
  { key: 3, img: Accesories3, name: "Accesories3" },
  { key: 4, img: Accesories4, name: "Accesories4" },
  { key: 5, img: Accesories5, name: "Accesories5" },
  { key: 6, img: Accesories6, name: "Accesories6" },
  { key: 7, img: Accesories7, name: "Accesories7" },
  { key: 8, img: Accesories8, name: "Accesories8" },
  { key: 9, img: Accesories9, name: "Accesories9" },
  { key: 10, img: Accesories10, name: "Accesories10" },
  { key: 11, img: Accesories11, name: "Accesories11" },
  { key: 12, img: Accesories12, name: "Accesories12" },
];

const maleEyesImg = [
  { key: 1, img: eyes1, name: "eyes1" },
  { key: 2, img: eyes2, name: "eyes2" },
  { key: 3, img: eyes3, name: "eyes3" },
  { key: 4, img: eyes4, name: "eyes4" },
  { key: 5, img: eyes5, name: "eyes5" },
  { key: 6, img: eyes6, name: "eyes6" },
  { key: 7, img: eyes7, name: "eyes7" },
  { key: 8, img: eyes8, name: "eyes8" },
  { key: 9, img: eyes9, name: "eyes9" },
  { key: 10, img: eyes10, name: "eyes10" },
  { key: 11, img: eyes11, name: "eyes11" },
  { key: 12, img: eyes12, name: "eyes12" },
  { key: 13, img: eyes13, name: "eyes13" },
  { key: 14, img: eyes14, name: "eyes14" },
  { key: 15, img: eyes15, name: "eyes15" },
  { key: 16, img: eyes16, name: "eyes16" },
  { key: 17, img: eyes17, name: "eyes17" },
  { key: 18, img: eyes18, name: "eyes18" },
  { key: 19, img: eyes19, name: "eyes19" },
  { key: 20, img: eyes20, name: "eyes20" },
];

const crossImg = [{ key: 1, img: Cross, name: "none" }];

const EyeWearImg = [
  { key: 1, img: eyeWear1, name: "none" },
  { key: 2, img: eyeWear2, name: "EyeWear2" },
  { key: 3, img: eyeWear3, name: "EyeWear3" },
  { key: 4, img: eyeWear4, name: "EyeWear4" },
  { key: 5, img: eyeWear5, name: "EyeWear5" },
  { key: 6, img: eyeWear6, name: "EyeWear6" },
  { key: 7, img: eyeWear7, name: "EyeWear7" },
  { key: 8, img: eyeWear8, name: "EyeWear8" },
  { key: 9, img: eyeWear9, name: "EyeWear9" },
  { key: 10, img: eyeWear10, name: "EyeWear10" },
  { key: 11, img: eyeWear11, name: "EyeWear11" },
  { key: 12, img: eyeWear12, name: "EyeWear12" },
  { key: 13, img: eyeWear13, name: "EyeWear13" },
  { key: 14, img: eyeWear14, name: "EyeWear14" },
  { key: 15, img: eyeWear15, name: "EyeWear15" },
];
const maleMouthImg = [
  { key: 1, img: mouth1, name: "mouth1" },
  { key: 2, img: mouth2, name: "mouth2" },
  { key: 3, img: mouth3, name: "mouth3" },
  { key: 4, img: mouth4, name: "mouth4" },
  { key: 5, img: mouth5, name: "mouth5" },
  { key: 6, img: mouth6, name: "mouth6" },
  { key: 7, img: mouth7, name: "mouth7" },
  { key: 8, img: mouth8, name: "mouth8" },
  { key: 9, img: mouth9, name: "mouth9" },
  { key: 10, img: mouth10, name: "mouth10" },
  { key: 11, img: mouth11, name: "mouth11" },
  { key: 12, img: mouth12, name: "mouth12" },
  { key: 13, img: mouth13, name: "mouth13" },
  { key: 14, img: mouth14, name: "mouth14" },
  { key: 15, img: mouth15, name: "mouth15" },
];

interface RightgridPanelI {
  setCanvasObject: React.Dispatch<React.SetStateAction<breedRequestBody>>;
  canvasObject: breedRequestBody;
  nameObject: breedRequestBody;
  setNameObject: React.Dispatch<React.SetStateAction<breedRequestBody>>;
}

function RightGridPanel(props: RightgridPanelI) {
  const [activeEyesWear, setActiveEyesWear] = useState<number>(0);
  const [activeAccessories, setActiveAccessories] = useState<number>(0);
  const [activeMouth, setActiveMouth] = useState<number>(0);
  const [activeEyes, setActiveEyes] = useState<number>(0);

  const [rightPanelValue, setRightPanelValue] = useState<number>(0);

  const handleChangeRightPanel = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setRightPanelValue(newValue);
  };

  const handleAccessories = (item: any) => {
    const newCharacterObject = structuredClone(props.canvasObject);
    newCharacterObject.childTraits.accesories = item.img;
    props.setCanvasObject(newCharacterObject);

    const newCharacterObjectName = structuredClone(props.nameObject);
    newCharacterObjectName.childTraits.accesories = item?.name;
    props.setNameObject(newCharacterObjectName);

    setActiveAccessories(item.key);
  };

  const handleEyesWear = (item: any) => {
    const newCharacterObject = structuredClone(props.canvasObject);
    newCharacterObject.childTraits.eyewear = item.img;
    props.setCanvasObject(newCharacterObject);

    const newCharacterObjectName = structuredClone(props.nameObject);
    newCharacterObjectName.childTraits.eyewear = item?.name;
    props.setNameObject(newCharacterObjectName);
    setActiveEyesWear(item.key);
  };

  const handleEyes = (item: any) => {
    const newCharacterObject = structuredClone(props.canvasObject);
    newCharacterObject.childTraits.eyes = item.img;
    props.setCanvasObject(newCharacterObject);

    const newCharacterObjectName = structuredClone(props.nameObject);
    newCharacterObjectName.childTraits.eyes = item?.name;
    props.setNameObject(newCharacterObjectName);
    setActiveEyes(item.key);
  };
  const handleMouth = (item: any) => {
    const newCharacterObject = structuredClone(props.canvasObject);
    newCharacterObject.childTraits.mouth = item.img;
    props.setCanvasObject(newCharacterObject);

    const newCharacterObjectName = structuredClone(props.nameObject);
    newCharacterObjectName.childTraits.mouth = item?.name;
    props.setNameObject(newCharacterObjectName);
    setActiveMouth(item.key);
  };

  return (
    <Box
      mt={1}
      // ml={2}
      sx={{
        background: "rgba(63, 63, 63, 0.9)",
        // height: "90%",
        borderRadius: "10px",
        width: "100%",
        pb: "4px",
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          variant="scrollable"
          value={rightPanelValue}
          onChange={handleChangeRightPanel}
          TabScrollButtonProps={{
            sx: { color: "white", size: "20px" },
          }}
          TabIndicatorProps={{
            sx: {
              backgroundColor: "#6DF8FE",
              paddingInline: -16,
            },
          }}
          sx={{
            "& button.Mui-selected": {
              color: "#6DF8FE",
            },
            padding: -16,
          }}
          // scrollButtons={"auto"}
          // visibleScrollbar={true}
        >
          <Tab
            label="Eyes"
            sx={{
              color: "white",
              borderRight: "4px solid rgba(109, 248, 254, 0.6)",
              background: "#003548",
            }}
          />
          <Tab
            label="EyesWear"
            sx={{
              color: "white",
              borderRight: "4px solid rgba(109, 248, 254, 0.6)",
              background: "#003548",
            }}
          />

          <Tab
            label="Accessories"
            sx={{
              color: "white",
              borderRight: "4px solid rgba(109, 248, 254, 0.6)",
              background: "#003548",
            }}
          />

          <Tab label="Mouth" sx={{ color: "white", background: "#003548" }} />
        </Tabs>
      </Box>
      {/* ///////////////////////////////////////////  Eyes  ///////////////////////////////////////////////////////// */}
      <TabPanel value={rightPanelValue} index={0}>
        <Box
          sx={{ background: "background: rgba(0, 0, 0, 0.5)" }}
          minHeight="55vh"
        >
          <Grid
            container
            spacing={{ sm: 2 }}
            columns={{ sm: 9 }}
            pl={2}
            minHeight="15vh"
            maxHeight="55vh"
          >
            {maleEyesImg.map((item, index) => (
              <Grid item sm={3} key={item.key}>
                {activeEyes == item.key ? (
                  <Box
                    width="6vw"
                    height="10vh"
                    className={styleGrids.grid}
                    key={item.key}
                    onClick={() => handleEyes(item)}
                    sx={{ border: "2px solid #6DF8FE" }}
                  >
                    <img
                      src={item.img}
                      alt=""
                      style={{ width: "6vw", height: "10vh" }}
                      key={index}
                    />
                  </Box>
                ) : (
                  <Box
                    width="6vw"
                    height="10vh"
                    className={styleGrids.grid}
                    key={item.key}
                    onClick={() => handleEyes(item)}
                  >
                    <img
                      src={item.img}
                      alt=""
                      style={{ width: "6vw", height: "10vh" }}
                      key={index}
                    />
                  </Box>
                )}
              </Grid>
            ))}
          </Grid>
        </Box>
      </TabPanel>
      {/* ///////////////////////////////////////////  EYES Wear  ///////////////////////////////////////////////////////// */}

      <TabPanel value={rightPanelValue} index={1}>
        <Box
          sx={{ background: "background: rgba(0, 0, 0, 0.5)" }}
          minHeight="55vh"
        >
          <Grid
            container
            spacing={{ sm: 2 }}
            columns={{ sm: 9 }}
            pl={2}
            maxHeight="55vh"
          >
            {EyeWearImg.map((item, index) => (
              <Grid item sm={3} key={item.key}>
                {item.key === 1 ? (
                  <>
                    <Box
                      width="6vw"
                      height="10vh"
                      className={styleGrids.grid}
                      key={item.key}
                      onClick={() => handleEyesWear(item)}
                      // sx={{ border: "2px solid #6DF8FE" }}
                    >
                      <img
                        src={crossImg[0].img}
                        alt=""
                        style={{ width: "6vw", height: "10vh" }}
                        key={index}
                      />
                    </Box>
                  </>
                ) : activeEyesWear == item.key ? (
                  <Box
                    width="6vw"
                    height="10vh"
                    className={styleGrids.grid}
                    key={item.key}
                    onClick={() => handleEyesWear(item)}
                    sx={{ border: "2px solid #6DF8FE" }}
                  >
                    <img
                      src={item.img}
                      alt=""
                      style={{ width: "6vw", height: "10vh" }}
                      key={index}
                    />
                  </Box>
                ) : (
                  <Box
                    width="6vw"
                    height="10vh"
                    className={styleGrids.grid}
                    key={item.key}
                    onClick={() => handleEyesWear(item)}
                  >
                    <img
                      src={item.img}
                      alt=""
                      style={{ width: "6vw", height: "10vh" }}
                      key={index}
                    />
                  </Box>
                )}
              </Grid>
            ))}
          </Grid>
        </Box>
      </TabPanel>

      {/* ////////////////////////////////////////////  Accessories //////////////////////////////////////// */}

      <TabPanel value={rightPanelValue} index={2}>
        <Box
          sx={{ background: "background: rgba(0, 0, 0, 0.5)" }}
          minHeight="55vh"
        >
          <Grid
            container
            spacing={{ sm: 2 }}
            columns={{ sm: 9 }}
            pl={2}
            minHeight="55vh"
            maxHeight="55vh"
          >
            {AccessoriesImg.map((item, index) => (
              <Grid item sm={3} key={item.key}>
                {item.key === 1 ? (
                  <>
                    <Box
                      width="6vw"
                      height="10vh"
                      className={styleGrids.grid}
                      key={item.key}
                      onClick={() => handleAccessories(item)}
                      // sx={{ border: "2px solid #6DF8FE" }}
                    >
                      <img
                        src={crossImg[0].img}
                        alt=""
                        style={{ width: "6vw", height: "10vh" }}
                        key={index}
                      />
                    </Box>
                  </>
                ) : activeAccessories == item.key ? (
                  <Box
                    width="6vw"
                    height="10vh"
                    className={styleGrids.grid}
                    key={item.key}
                    onClick={() => handleAccessories(item)}
                    sx={{ border: "2px solid #6DF8FE" }}
                  >
                    <img
                      src={item.img}
                      alt=""
                      style={{ width: "6vw", height: "10vh" }}
                      key={index}
                    />
                  </Box>
                ) : (
                  <Box
                    width="6vw"
                    height="10vh"
                    className={styleGrids.grid}
                    key={item.key}
                    onClick={() => handleAccessories(item)}
                  >
                    <img
                      src={item.img}
                      alt=""
                      style={{ width: "6vw", height: "10vh" }}
                      key={index}
                    />
                  </Box>
                )}
              </Grid>
            ))}
          </Grid>
        </Box>
      </TabPanel>

      {/* ////////////////////////////////////////////  Mouth ///////////////////////////////////////// */}

      <TabPanel value={rightPanelValue} index={3}>
        <Box
          sx={{ background: "background: rgba(0, 0, 0, 0.5)" }}
          minHeight="55vh"
        >
          <Grid
            container
            spacing={{ sm: 2 }}
            columns={{ sm: 9 }}
            pl={2}
            minHeight="55vh"
            maxHeight="55vh"
          >
            {maleMouthImg.map((item, index) => (
              <Grid item sm={3} key={item.key}>
                {activeMouth == item.key ? (
                  <Box
                    width="6vw"
                    height="10vh"
                    className={styleGrids.grid}
                    key={item.key}
                    onClick={() => handleMouth(item)}
                    sx={{ border: "2px solid #6DF8FE" }}
                  >
                    <img
                      src={item.img}
                      alt=""
                      style={{ width: "6vw", height: "10vh" }}
                      key={index}
                    />
                  </Box>
                ) : (
                  <Box
                    width="6vw"
                    height="10vh"
                    className={styleGrids.grid}
                    key={item.key}
                    onClick={() => handleMouth(item)}
                  >
                    <img
                      src={item.img}
                      alt=""
                      style={{ width: "6vw", height: "10vh" }}
                      key={index}
                    />
                  </Box>
                )}
              </Grid>
            ))}
          </Grid>
        </Box>
      </TabPanel>
    </Box>
  );
}

export default RightGridPanel;
export interface characterObjectI {
  issuer: string;
  attributes: {
    background: string;
    hair: string;
    eyewear: string;
    mouth: string;
    clothes: string;
    head: string;
    skin: string;
    eyes: string;
  };
}
