import React, { useState, useEffect } from "react";
import { breedRequestBody } from "../DesignGrids/DesginGrids";
import { Box, Paper } from "@mui/material";
import { Stage, Layer, Image } from "react-konva";

interface CanvasPropsI {
  charObj: breedRequestBody;
}

const Canvas = ({ charObj }: CanvasPropsI) => {
  const [background, setBackground] = useState<any>(null);
  const [eyewear, setEyewear] = useState<any>(null);
  const [skin, setSkin] = useState<any>(null);
  const [mouth, setMouth] = useState<any>(null);
  const [clothes, setClothes] = useState<any>(null);
  const [eyes, setEyes] = useState<any>(null);
  const [accesories, setAccesories] = useState<any>(null);
  const [headgear, setHeadgear] = useState<any>(null);

  const stageRef = React.useRef<any>();

  const canwasWidth = 300;
  const canvasHeight = 275;

  useEffect(() => {
    loadImage();
  }, [
    charObj.childTraits.background,
    charObj.childTraits.eyewear,
    charObj.childTraits.accesories,
    charObj.childTraits.mouth,
    charObj.childTraits.clothing,
    charObj.childTraits.eyes,
    charObj.childTraits.headgear,
    charObj.childTraits.skin,
    charObj.uri,
    charObj.childTraits.gender,
  ]);

  function loadImage() {
    const image1 = new window.Image();
    image1.src = charObj.childTraits.background;
    image1.onload = () => {
      setBackground(image1);
    };
    const image2 = new window.Image();
    image2.src = charObj.childTraits.skin;
    image2.onload = () => {
      setSkin(image2);
    };

    const image3 = new window.Image();
    image3.src = charObj.childTraits.clothing;
    image3.onload = () => {
      setClothes(image3);
    };

    const image4 = new window.Image();
    image4.src = charObj.childTraits.eyes;
    image4.onload = () => {
      setEyes(image4);
    };
    const image5 = new window.Image();
    image5.src = charObj.childTraits.mouth;
    image5.onload = () => {
      setMouth(image5);
    };
    const image6 = new window.Image();
    image6.src = charObj.childTraits.eyewear;
    image6.onload = () => {
      setEyewear(image6);
    };
    const image7 = new window.Image();
    image7.src = charObj.childTraits.headgear;
    image7.onload = () => {
      setHeadgear(image7);
    };

    const image8 = new window.Image();
    image8.src = charObj.childTraits.accesories;
    image8.onload = () => {
      setAccesories(image8);
    };

    charObj.uri = stageRef.current.toDataURL();
  }
  function downloadURI(uri: any, name: string) {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const handleExport = () => {
    const uri = stageRef.current.toDataURL();
    downloadURI(uri, "123.png");
  };

  return (
    <Box
      textAlign={"center"}
      sx={{
        width: canwasWidth,
        paddingTop: 1,
      }}
    >
      <Paper elevation={4} sx={{ background: "#D9D9D9" }}>
        <Stage width={canwasWidth} height={canvasHeight} ref={stageRef}>
          <Layer>
            <Image
              width={canwasWidth}
              height={canvasHeight}
              image={background}
              y={0}
              x={0}
            ></Image>
          </Layer>

          <Layer>
            <Image
              width={canwasWidth}
              height={canvasHeight}
              image={skin}
              y={0}
              x={0}
            ></Image>
          </Layer>

          <Layer>
            <Image
              width={canwasWidth}
              height={canvasHeight}
              image={clothes}
              y={0}
              x={0}
            ></Image>
          </Layer>

          <Layer>
            <Image
              width={canwasWidth}
              height={canvasHeight}
              image={eyes}
              y={0}
              x={0}
            ></Image>
          </Layer>
          <Layer>
            <Image
              width={canwasWidth}
              height={canvasHeight}
              image={eyewear}
              y={0}
              x={0}
            ></Image>
          </Layer>
          <Layer>
            <Image
              width={canwasWidth}
              height={canvasHeight}
              image={accesories}
              y={0}
              x={0}
            ></Image>
          </Layer>
          <Layer>
            <Image
              width={canwasWidth}
              height={canvasHeight}
              image={mouth}
              y={0}
              x={0}
            ></Image>
          </Layer>
          <Layer>
            <Image
              width={canwasWidth}
              height={canvasHeight}
              image={headgear}
              y={0}
              x={0}
            ></Image>
          </Layer>
        </Stage>
      </Paper>
      {/* <Button onClick={handleExport}>Click here to log stage data URL</Button> */}
    </Box>
  );
};

export default Canvas;
