import axios from "axios";

const ENVIRONMENT: environmentType = "PRODUCTION";

export let API: API_I;

function initApi(ENVIRONMENT_ARG: environmentType) {
  if (ENVIRONMENT_ARG === "DEVELOPMENT") {
    API = {
      node_backend: "http://localhost:60000/",
      socket_backend: "http://localhost:9000/",
      cubApiURL: "api/luxlion/cubs",
      ledger: "https://livenet.xrpl.org",
    };
  } else {
    API = {
      node_backend: "https://backend.luxlionsnft.com/",
      socket_backend: "https://www.socket.luxlionsnft.com",
      cubApiURL: "api/luxlion/cubs",
      ledger: "https://livenet.xrpl.org",
    };
  }
}

initApi(ENVIRONMENT);

export const axiosRequest = async (
  method_: methodType,
  url_: string,
  body?: any,
  params_?: any
) => {
  const URL = API.node_backend + url_;

  const response = await axios({
    method: method_,
    url: URL,
    data: body,
    params: params_,
  });
  return response;
};

export const axiosRequestAuth = async (
  method_: methodType,
  url_: string,
  body?: any,
  params_?: any
) => {
  const token = localStorage.getItem("token");
  const URL = API.node_backend + url_;

  const response = await axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: method_,
    url: URL,
    data: body,
    params: params_,
  });
  return response;
};

interface API_I {
  node_backend: string;
  socket_backend: string;
  cubApiURL: `api/luxlion/cubs`;
  ledger: `https://livenet.xrpl.org` | `https://testnet.xrpl.org/`;
}

type methodType = "get" | "post" | "patch" | "delete";

type environmentType = "PRODUCTION" | "DEVELOPMENT";
