import { Box } from "@mui/material";
import BubblesImg from "../../../assets/BreedingAssets/Bubbles.gif";

interface Props {
  isScreenLessThan1725px: boolean;
}

const BubbleImage: React.FC<Props> = ({ isScreenLessThan1725px }) => {
  const topPosition = isScreenLessThan1725px ? 270 : 350;

  return (
    <Box
      width="100%"
      position="absolute"
      sx={{ m: 0, top: topPosition, left: "40%", scroll: "hidden" }}
    >
      <Box>
        <img src={`${BubblesImg}`} alt="" width="16%" />
      </Box>
    </Box>
  );
};

export default BubbleImage;
