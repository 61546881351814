import React, { useRef, useEffect, useState } from "react";

import ArrowNext from "../../assets/PotionAssets/arrowNext.png";
import ArrowBack from "../../assets/PotionAssets/arrowBack.png";
import { Swiper, SwiperSlide } from "swiper/react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { setPotionId } from "../../redux/PotionReducer/PotionReducer";
import StylePotion from "./PotionCarousel.module.css";

// Import Swiper styles
import "swiper/css";
import "./PotionCarousel.css";
import { Box, Button, Stack, Typography } from "@mui/material";
import { validNftsI } from "../../types/nftTypes";

function PotionCarousel() {
  const [activePotion, setActivePotion] = useState<string>("");
  const dispatch = useDispatch();

  const swiperRef = useRef<any>();
  const bigScreenCheck = useMediaQuery("(max-width:1725px)");

  const nfts: validNftsI | undefined = useSelector(
    (state: any) => state.nfts?.nftObj
  );

  const setPotionIdFunc = (id: any) => {
    dispatch(setPotionId(id));
    setActivePotion(id);
  };

  return (
    <>
      <Stack direction="row">
        <Button onClick={() => swiperRef.current?.slidePrev()}>
          <img src={`${ArrowBack}`} alt="" style={{ width: 15 }} />
        </Button>

        {nfts?.potionNfts?.length === 1 ? (
          <Swiper
            width={300}
            spaceBetween={2}
            slidesPerView={nfts?.potionNfts?.length === 1 ? 1 : 2}
            allowSlidePrev={false}
            allowSlideNext={false}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
          >
            {nfts?.potionNfts ? (
              <Box sx={{ p: 2 }}>
                {bigScreenCheck ? (
                  <>
                    {nfts?.potionNfts.map((potion, index) =>
                      activePotion == potion.NFTokenID ? (
                        <SwiperSlide
                          style={{ paddingBlock: 2 }}
                          onClick={() => setPotionIdFunc(potion.NFTokenID)}
                        >
                          <img
                            src={potion.contents.image}
                            alt={potion.contents.name}
                            crossOrigin="anonymous"
                            style={{
                              width: 55,
                              borderRadius: 6,
                              border: "4px solid #0000FF",
                            }}
                            key={potion.NFTokenID}
                          />
                        </SwiperSlide>
                      ) : (
                        <SwiperSlide
                          style={{ paddingBlock: 3 }}
                          onClick={() => setPotionIdFunc(potion.NFTokenID)}
                        >
                          <img
                            src={potion.contents.image}
                            alt={potion.contents.name}
                            crossOrigin="anonymous"
                            style={{ width: 55, borderRadius: 6 }}
                            key={potion.NFTokenID}
                          />
                        </SwiperSlide>
                      )
                    )}
                  </>
                ) : (
                  nfts?.potionNfts.map((potion: any, index: number) =>
                    activePotion == potion.NFTokenID ? (
                      <SwiperSlide
                        style={{ paddingBlock: -2 }}
                        onSelect={() => setPotionIdFunc(potion.NFTokenID)}
                        // onSelect
                      >
                        <img
                          src={potion.contents.image}
                          alt={potion.contents.name}
                          crossOrigin="anonymous"
                          style={{
                            width: 75,
                            borderRadius: 8,
                            border: "4px solid #0000FF",
                          }}
                          key={potion.NFTokenID}
                        />
                      </SwiperSlide>
                    ) : (
                      <SwiperSlide
                        style={{ paddingBlock: -2 }}
                        onClick={() => setPotionIdFunc(potion.NFTokenID)}
                      >
                        <img
                          src={potion.contents.image}
                          alt={potion.contents.name}
                          crossOrigin="anonymous"
                          style={{ width: 75, borderRadius: 8 }}
                          key={potion.NFTokenID}
                        />
                      </SwiperSlide>
                    )
                  )
                )}
              </Box>
            ) : (
              <Box sx={{ zIndex: 99 }}>
                <Typography sx={{ color: "black" }}>
                  {"No potion to burn"}
                </Typography>
              </Box>
            )}
          </Swiper>
        ) : (
          <>
            {nfts && nfts?.potionNfts?.length > 4 ? (
              <Swiper
                spaceBetween={2}
                slidesPerView={nfts?.potionNfts?.length === 3 ? 3 : 4}
                onSwiper={(swiper) => console.log(swiper, "12")}
                onBeforeInit={(swiper) => {
                  swiperRef.current = swiper;
                }}
                // style={{ paddingInline: 30 }}
              >
                {nfts?.potionNfts ? (
                  <Box sx={{ p: 2 }}>
                    {bigScreenCheck ? (
                      <>
                        {nfts?.potionNfts.map((potion: any, index: number) =>
                          activePotion == potion.NFTokenID ? (
                            <SwiperSlide
                              style={{ paddingBlock: 2, marginTop: 0 }}
                              onClick={() => setPotionIdFunc(potion.NFTokenID)}
                            >
                              <img
                                src={potion.contents.image}
                                alt={potion.contents.name}
                                crossOrigin="anonymous"
                                style={{
                                  width: 55,
                                  borderRadius: 6,
                                  border: "4px solid #0000FF",
                                }}
                                key={potion.NFTokenID}
                              />
                            </SwiperSlide>
                          ) : (
                            <SwiperSlide
                              style={{ paddingBlock: 1 }}
                              onClick={() => setPotionIdFunc(potion.NFTokenID)}
                            >
                              <img
                                src={potion.contents.image}
                                alt={potion.contents.name}
                                crossOrigin="anonymous"
                                style={{ width: 55, borderRadius: 6 }}
                                key={potion.NFTokenID}
                              />
                            </SwiperSlide>
                          )
                        )}
                      </>
                    ) : (
                      nfts?.potionNfts.map((potion: any, index: number) =>
                        activePotion == potion.NFTokenID ? (
                          <SwiperSlide
                            style={{ paddingBlock: -2 }}
                            onSelect={() => setPotionIdFunc(potion.NFTokenID)}
                            // onSelect
                          >
                            <img
                              src={potion.contents.image}
                              alt={potion.contents.name}
                              crossOrigin="anonymous"
                              style={{
                                width: 75,
                                borderRadius: 8,
                                border: "4px solid #0000FF",
                              }}
                              key={potion.NFTokenID}
                            />
                          </SwiperSlide>
                        ) : (
                          <SwiperSlide
                            style={{ paddingBlock: -2 }}
                            onClick={() => setPotionIdFunc(potion.NFTokenID)}
                          >
                            <img
                              src={potion.contents.image}
                              alt={potion.contents.name}
                              crossOrigin="anonymous"
                              style={{ width: 75, borderRadius: 8 }}
                              key={potion.NFTokenID}
                            />
                          </SwiperSlide>
                        )
                      )
                    )}
                  </Box>
                ) : (
                  <Box sx={{ zIndex: 99 }}>
                    <Typography sx={{ color: "black" }}>
                      {"No potion to burn"}
                    </Typography>
                  </Box>
                )}
              </Swiper>
            ) : (
              <Swiper
                width={300}
                spaceBetween={20}
                slidesPerView={nfts?.potionNfts?.length == 2 ? 2 : 3}
                onSwiper={(swiper) => console.log(swiper, "12")}
                onBeforeInit={(swiper) => {
                  swiperRef.current = swiper;
                }}
                // style={{ paddingInline: 30 }}
              >
                {nfts?.potionNfts ? (
                  <Box sx={{ p: 2 }}>
                    {bigScreenCheck ? (
                      <>
                        {nfts?.potionNfts.map((potion: any, index: number) =>
                          activePotion == potion.NFTokenID ? (
                            <SwiperSlide
                              style={{ paddingBlock: 2 }}
                              onClick={() => setPotionIdFunc(potion.NFTokenID)}
                            >
                              <img
                                src={potion.contents.image}
                                alt={potion.contents.name}
                                crossOrigin="anonymous"
                                style={{
                                  width: 55,
                                  borderRadius: 6,
                                  border: "4px solid #0000FF",
                                }}
                                key={potion.NFTokenID}
                              />
                            </SwiperSlide>
                          ) : (
                            <SwiperSlide
                              style={{ paddingBlock: 3 }}
                              onClick={() => setPotionIdFunc(potion.NFTokenID)}
                            >
                              <img
                                src={potion.contents.image}
                                alt={potion.contents.name}
                                crossOrigin="anonymous"
                                style={{ width: 55, borderRadius: 6 }}
                                key={potion.NFTokenID}
                              />
                            </SwiperSlide>
                          )
                        )}
                      </>
                    ) : (
                      nfts?.potionNfts.map((potion: any, index: number) =>
                        activePotion == potion.NFTokenID ? (
                          <SwiperSlide
                            style={{ paddingBlock: -2 }}
                            onSelect={() => setPotionIdFunc(potion.NFTokenID)}
                            // onSelect
                          >
                            <img
                              src={potion.contents.image}
                              alt={potion.contents.name}
                              crossOrigin="anonymous"
                              style={{
                                width: 75,
                                borderRadius: 8,
                                border: "4px solid #0000FF",
                              }}
                              key={potion.NFTokenID}
                            />
                          </SwiperSlide>
                        ) : (
                          <SwiperSlide
                            style={{ paddingBlock: -2 }}
                            onClick={() => setPotionIdFunc(potion.NFTokenID)}
                          >
                            <img
                              src={potion.contents.image}
                              alt={potion.contents.name}
                              crossOrigin="anonymous"
                              style={{ width: 75, borderRadius: 8 }}
                              key={potion.NFTokenID}
                            />
                          </SwiperSlide>
                        )
                      )
                    )}
                  </Box>
                ) : (
                  <Box sx={{ zIndex: 99 }}>
                    <Typography sx={{ color: "black" }}>
                      {"No potion to burn"}
                    </Typography>
                  </Box>
                )}
              </Swiper>
            )}
          </>
        )}

        <Button onClick={() => swiperRef.current?.slideNext()} size="small">
          <img src={`${ArrowNext}`} alt="" style={{ width: 15 }} />
        </Button>
      </Stack>
    </>
  );
}

export default PotionCarousel;
