import { XummPkce, ResolvedFlow } from "xumm-oauth2-pkce";
import { useDispatch } from "react-redux";
import { login } from "../../redux/LoginReducers/LoginReducers";
import { setLoading } from "../../redux/LoaderReducer/LoaderReducer";
import { Box, Button } from "@mui/material";
import LoginButtonImg from "../../assets/HomeAssets/login.png";
import { useNavigate, redirect } from "react-router-dom";
import { axiosRequest } from "../api/api";
import { setNFT } from "../../redux/NFTReducer/NFTReducer";
import React, { useEffect, useState } from "react";

import { setApiHit } from "../../redux/LoaderReducer/LoaderReducer";

export const XUMM_JWKS_URL = "https://oauth2.xumm.app/certs";

const XUMM_API_KEY: any = process.env.REACT_APP_XUMM_API_KEY;



const xumm = new XummPkce(XUMM_API_KEY);

// xumm.on("retrieved", async () => {
//    const navigate = useNavigate();
//    const dispatch = useDispatch();
//   console.log("Retrieved: from localStorage or mobile browser redirect");
//   let account = await xumm.state();
//   dispatch(login(account))
//   if (account) {
//     localStorage.setItem("token", account.jwt);
//   }
//   redirect("/breeding");
//   alert(account?.jwt);
// });

const getNftURL = "api/luxlion/nfts?userAccount=";

const LoginComp = () => {
  let mblToken = localStorage.getItem("mbltoken");

  const navigate = useNavigate();

  useEffect(() => {
    xumm.on("retrieved", async () => {

      console.log("Retrieved: from localStorage or mobile browser redirect");
      let account = await xumm.state();
      if (account) {
        getNFT(account.me.account);
        dispatch(login(account));
        localStorage.setItem("token", account.jwt);
        localStorage.setItem("accnum", account.me.account);

        setTimeout(() => navigate("breeding"), 500);
      }
    });
  }, []);

  const dispatch = useDispatch();
  async function getNFT(data: any) {
    dispatch(setLoading(true));
    try {
      const response: any = await axiosRequest(
        "get",
        `${getNftURL}${data}`,
        undefined,
        undefined
      );

      dispatch(setNFT(response));
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      console.error(error);
    }
  }

  function XummLogin() {
    if (xumm) {
      xumm.authorize()?.then((response) => {
        dispatch(login(response));
        if (response) {
          getNFT(response.me.account);
          dispatch(setApiHit(true));

          localStorage.setItem("token", response.jwt);
          localStorage.setItem("accnum", response.me.account);
        }
      });
    }
    // dispatch(login("data"));
  }

  return (
    <Box>
      <Button
        variant="contained"
        sx={{
          borderRadius: "5px",
          color: "white",
          background: "#000000",
          mt: -20,
          padding: "unset",
          "&:hover": {
            background: "black",
            border: "1px solid #6AEFFD",
          },
          width: "250px",
        }}
        onClick={XummLogin}
      >
        <img src={`${LoginButtonImg}`} width="90%" />
      </Button>
    </Box>
  );
};
export default LoginComp;
