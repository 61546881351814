import { useEffect, useState } from "react";
import { Box, Grid, Button, Typography, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import ArrowLeft from "../../../assets/BreedingAssets/ArrowLeft.png";
import ArrowRight from "../../../assets/BreedingAssets/ArrowRight.png";
import { setMale, setFemale } from "../../../redux/GenderReducer/GenderReducer";
import FemaleCarousel from "../../Carousel/FemaleCarousel";
import LuxLionCard from "../../LuxLionCard/LuxLionCard";

const FemaleCarouselFunc = () => {
  const bigScreenCheck = useMediaQuery("(max-width:1725px)");
  const [femaleCount, setFemaleCount] = useState<number>(0);
  const [femaleCardState, setFemaleCardState] = useState<any>([]);
  const [showNumber, setShowNumber] = useState<any>(0);

  const nfts = useSelector((state: any) => state.nfts?.nftObj);

  const femaleCards: Array<any> = [];
  const dispatch = useDispatch();
  let counting = 0;

  let femaleCounting = 0;

  let counting22 = 0;

  let femaleCounting22 = 0;

  useEffect(() => {
    setCardData();
    dispatch(setFemale(nfts?.femaleNfts[0]?.NFTokenID));
  }, []);
  const setCardData = () => {
    for (let i = 0; i < nfts?.femaleNfts.length; i++) {
      let obj = {
        key: i,
        content: (
          <LuxLionCard
            imagen={nfts?.femaleNfts[i]?.contents?.image}
            info={nfts?.femaleNfts[i]?.contents}
          />
        ),
        id: nfts?.femaleNfts[i]?.NFTokenID,
      };
      femaleCards.push(obj);
    }

    setFemaleCardState(femaleCards);
  };
  const tableFemale = femaleCardState.map((element: any, index: number) => {
    return {
      ...element,
      onClick: () => {
        setingGenderFunc(element, index);
      },
    };
  });

  const setingGenderFunc = (genders: any, index: number) => {
    dispatch(setFemale(genders.id));
    setFemaleCount(index);
    femaleCounting = index;
  };

  const settingCountLeft = () => {
    const femaleNftsLength = nfts?.femaleNfts.length;
    if (femaleCount >= femaleNftsLength - 1) {
      setFemaleCount(0);
      femaleCounting = 0;
      counting = 0;
    } else {
      counting = femaleCount + 1;
      setFemaleCount(counting);
      femaleCounting = counting;
      // dispatch(setMale(table?.card[femaleCount-1].));
    }
    //////////////////////////// card number   ///////////////////////////////////

    if (showNumber === 0) {
      setShowNumber(femaleNftsLength - 1);

      counting22 = femaleNftsLength - 1;
      femaleCounting22 = femaleNftsLength - 1;
    } else if (showNumber <= femaleNftsLength - 1) {
      counting22 = showNumber - 1;
      setShowNumber(counting22);
      femaleCounting22 = counting22;
    }

    dispatch(setFemale(tableFemale[femaleCounting]?.id));
  };

  const settingCountRight = () => {
    const femaleNftsLength = nfts?.femaleNfts.length;

    if (femaleCount === 0) {
      setFemaleCount(femaleNftsLength - 1);

      counting = femaleNftsLength - 1;
      femaleCounting = femaleNftsLength - 1;
    } else if (femaleCount <= femaleNftsLength - 1) {
      counting = femaleCount - 1;
      setFemaleCount(counting);
      femaleCounting = counting;
    }

    //////////////////////////// card number   ///////////////////////////////////

    if (showNumber >= femaleNftsLength - 1) {
      setShowNumber(0);
      femaleCounting22 = 0;
      counting22 = 0;
    } else {
      counting22 = showNumber + 1;
      setShowNumber(counting22);
      femaleCounting22 = counting22;
      // dispatch(setMale(table?.card[femaleCount-1].));
    }

    dispatch(setFemale(tableFemale[femaleCounting]?.id));
  };

  return (
    <>
      <FemaleCarousel
        cards={tableFemale}
        height="500px"
        width="100%"
        margin="0 auto"
        offset={20}
        showArrows={true}
        setFemaleCount={setFemaleCount}
        femaleCount={femaleCount}
      />
      {!bigScreenCheck ? (
        <Box sx={{ mt: -15 }} display="flex" flexDirection={"row"}>
          <Grid container>
            <Grid item sm={4} style={{ zIndex: "99" }}>
              <Box onClick={() => settingCountLeft()}>
                <img
                  style={{
                    marginBottom: 0,
                    width: 18,
                    marginLeft: 5,
                  }}
                  src={ArrowLeft}
                  alt=""
                />
              </Box>
            </Grid>
            <Grid item sm={4} display={"flex"} justifyContent="center">
              <Box style={{ textAlign: "center", display: "flex" }}>
                <Typography
                  fontFamily={"Jedi"}
                  variant="h4"
                  sx={{ color: "#ffffff", zIndex: "99" }}
                >
                  {`${showNumber + 1}/${nfts?.femaleNfts.length}`}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              sm={4}
              display={"flex"}
              justifyContent="right"
              style={{ zIndex: "99" }}
            >
              <Box onClick={() => settingCountRight()}>
                <img
                  style={{
                    paddingTop: 0,
                    width: 18,
                    marginLeft: 0,
                  }}
                  src={ArrowRight}
                  alt=""
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box sx={{ mt: -22.5 }} display="flex" flexDirection={"row"}>
          <Grid container>
            <Grid item sm={4} style={{ zIndex: "99" }}>
              <Box onClick={() => settingCountLeft()}>
                <img
                  style={{
                    marginBottom: 0,
                    width: 18,
                    marginLeft: 5,
                  }}
                  src={ArrowLeft}
                  alt=""
                />
              </Box>
            </Grid>
            <Grid item sm={4} display={"flex"} justifyContent="center">
              <Box style={{ textAlign: "center", display: "flex" }}>
                <Typography
                  fontFamily={"Jedi"}
                  variant="h6"
                  sx={{ color: "#ffffff", zIndex: "99" }}
                >
                  {`${showNumber + 1}/${nfts?.femaleNfts.length}`}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              sm={4}
              display={"flex"}
              justifyContent="right"
              style={{ zIndex: "99" }}
            >
              <Box onClick={() => settingCountRight()}>
                <img
                  style={{
                    paddingTop: 0,
                    width: 18,
                    marginLeft: 0,
                  }}
                  src={ArrowRight}
                  alt=""
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default FemaleCarouselFunc;
