import "./App.css";

import BackgroundImage from "./assets/Background/backgroundImage.png";
import { StyledEngineProvider } from "@mui/material/styles";
import CustomizedSnackbars from "./Components/SnackbarComp/SnackbarComp";
import AllRoutes from "./Routes/Routes";

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <CustomizedSnackbars />
      <div style={{ position: "relative" }}>
        <img
          src={`${BackgroundImage}`}
          alt=""
          style={{ width: "100%", height: "100vh", position: "absolute" }}
        />
        <AllRoutes />
      </div>
    </StyledEngineProvider>
  );
}

export default App;
