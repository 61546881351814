import React, { useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import TabPanel from "../../TabPanels/TabPanels";
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  Tab,
  Tabs,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { breedRequestBody } from "../DesginGrids";
import styleGrids from "./DesignGridsPanels.module.css";
import Cross from "../../../assets/cross.png";

import Background1 from "../../../assets/DesignAssets/Background/background1.png";
import Background2 from "../../../assets/DesignAssets/Background/background2.png";
import Background3 from "../../../assets/DesignAssets/Background/background3.png";
import Background4 from "../../../assets/DesignAssets/Background/background4.png";
import Background5 from "../../../assets/DesignAssets/Background/background5.png";
import Background6 from "../../../assets/DesignAssets/Background/background6.png";
import Background7 from "../../../assets/DesignAssets/Background/background7.png";
import Background8 from "../../../assets/DesignAssets/Background/background8.png";
import Background9 from "../../../assets/DesignAssets/Background/background9.png";
import Background10 from "../../../assets/DesignAssets/Background/background10.png";
import Background11 from "../../../assets/DesignAssets/Background/background11.png";
import Background12 from "../../../assets/DesignAssets/Background/background12.png";
import Background13 from "../../../assets/DesignAssets/Background/background13.png";
import Background14 from "../../../assets/DesignAssets/Background/background14.png";
import Background15 from "../../../assets/DesignAssets/Background/background15.png";
import Background16 from "../../../assets/DesignAssets/Background/background16.png";
import Background17 from "../../../assets/DesignAssets/Background/background17.png";
import Background18 from "../../../assets/DesignAssets/Background/background18.png";
import Background19 from "../../../assets/DesignAssets/Background/background19.png";
import Background20 from "../../../assets/DesignAssets/Background/background20.png";
import Background21 from "../../../assets/DesignAssets/Background/background21.png";
import Background22 from "../../../assets/DesignAssets/Background/background22.png";
import Background23 from "../../../assets/DesignAssets/Background/background23.png";
import Background24 from "../../../assets/DesignAssets/Background/background24.png";
import Background25 from "../../../assets/DesignAssets/Background/background25.png";
import Background26 from "../../../assets/DesignAssets/Background/background26.png";
import Background27 from "../../../assets/DesignAssets/Background/background27.png";
import Background28 from "../../../assets/DesignAssets/Background/background28.png";
import Background29 from "../../../assets/DesignAssets/Background/background29.png";
import Background30 from "../../../assets/DesignAssets/Background/background30.png";
import Background31 from "../../../assets/DesignAssets/Background/background31.png";
import Background32 from "../../../assets/DesignAssets/Background/background32.png";
import Background33 from "../../../assets/DesignAssets/Background/background33.png";
import Background34 from "../../../assets/DesignAssets/Background/background34.png";
import Background35 from "../../../assets/DesignAssets/Background/background35.png";
import Background36 from "../../../assets/DesignAssets/Background/background36.png";
import Background37 from "../../../assets/DesignAssets/Background/background37.png";
import Background38 from "../../../assets/DesignAssets/Background/background38.png";
import Background39 from "../../../assets/DesignAssets/Background/background39.png";
import Background40 from "../../../assets/DesignAssets/Background/background40.png";
import Background41 from "../../../assets/DesignAssets/Background/background41.png";
import Background42 from "../../../assets/DesignAssets/Background/background41a.png";
import Background43 from "../../../assets/DesignAssets/Background/background42.png";
import Background44 from "../../../assets/DesignAssets/Background/background43.png";
import Background45 from "../../../assets/DesignAssets/Background/background44.png";
import Background46 from "../../../assets/DesignAssets/Background/background45.png";
import Background47 from "../../../assets/DesignAssets/Background/background46.png";
import Background48 from "../../../assets/DesignAssets/Background/background47.png";
import Background49 from "../../../assets/DesignAssets/Background/background48.png";
import Background50 from "../../../assets/DesignAssets/Background/background49.png";
import Background51 from "../../../assets/DesignAssets/Background/background50.png";
import Background52 from "../../../assets/DesignAssets/Background/background51.png";
import Background53 from "../../../assets/DesignAssets/Background/background52.png";
import Background54 from "../../../assets/DesignAssets/Background/background53.png";
import Background55 from "../../../assets/DesignAssets/Background/background54.png";
import Background56 from "../../../assets/DesignAssets/Background/background55.png";
import Background57 from "../../../assets/DesignAssets/Background/background56.png";
import Background58 from "../../../assets/DesignAssets/Background/background57.png";
import Background59 from "../../../assets/DesignAssets/Background/background58.png";

import Head1 from "../../../assets/DesignAssets/HeadGear/transparent.png";
import Head2 from "../../../assets/DesignAssets/HeadGear/HEADGEAR2.png";
import Head3 from "../../../assets/DesignAssets/HeadGear/HEADGEAR3.png";
import Head4 from "../../../assets/DesignAssets/HeadGear/HEADGEAR4.png";
import Head5 from "../../../assets/DesignAssets/HeadGear/HEADGEAR5.png";
import Head6 from "../../../assets/DesignAssets/HeadGear/HEADGEAR6.png";
import Head7 from "../../../assets/DesignAssets/HeadGear/HEADGEAR7.png";
import Head8 from "../../../assets/DesignAssets/HeadGear/HEADGEAR8.png";
import Head9 from "../../../assets/DesignAssets/HeadGear/HEADGEAR9.png";
import Head10 from "../../../assets/DesignAssets/HeadGear/HEADGEAR10.png";
import Head11 from "../../../assets/DesignAssets/HeadGear/HEADGEAR11.png";
import Head12 from "../../../assets/DesignAssets/HeadGear/HEADGEAR12.png";
import Head13 from "../../../assets/DesignAssets/HeadGear/HEADGEAR13.png";
import Head14 from "../../../assets/DesignAssets/HeadGear/HEADGEAR14.png";
import Head15 from "../../../assets/DesignAssets/HeadGear/HEADGEAR15.png";
import Head16 from "../../../assets/DesignAssets/HeadGear/HEADGEAR16.png";
import Head17 from "../../../assets/DesignAssets/HeadGear/HEADGEAR17.png";

import Head18 from "../../../assets/DesignAssets/HeadGear/HEADGEAR18.png";
import Head19 from "../../../assets/DesignAssets/HeadGear/HEADGEAR19.png";
import Head20 from "../../../assets/DesignAssets/HeadGear/HEADGEAR20.png";
import Head21 from "../../../assets/DesignAssets/HeadGear/HEADGEAR1.png";

import skin1 from "../../../assets/DesignAssets/SkinFemale/SKIN1.png";
import skin2 from "../../../assets/DesignAssets/SkinFemale/SKIN2.png";
import skin3 from "../../../assets/DesignAssets/SkinFemale/SKIN3.png";
import skin4 from "../../../assets/DesignAssets/SkinFemale/SKIN4.png";
import skin5 from "../../../assets/DesignAssets/SkinFemale/SKIN5.png";
import skin6 from "../../../assets/DesignAssets/SkinFemale/SKIN6.png";
import skin7 from "../../../assets/DesignAssets/SkinFemale/SKIN7.png";
import skin8 from "../../../assets/DesignAssets/SkinFemale/SKIN8.png";
import skin9 from "../../../assets/DesignAssets/SkinFemale/SKIN9.png";
import skin10 from "../../../assets/DesignAssets/SkinFemale/SKIN10.png";
import skin11 from "../../../assets/DesignAssets/SkinFemale/SKIN11.png";
import skin12 from "../../../assets/DesignAssets/SkinFemale/SKIN12.png";
import skin13 from "../../../assets/DesignAssets/SkinFemale/SKIN13.png";
import skin14 from "../../../assets/DesignAssets/SkinFemale/SKIN14.png";
import skin15 from "../../../assets/DesignAssets/SkinFemale/SKIN15.png";
import skin16 from "../../../assets/DesignAssets/SkinFemale/SKIN16.png";
import skin17 from "../../../assets/DesignAssets/SkinFemale/SKIN17.png";
import skin18 from "../../../assets/DesignAssets/SkinFemale/SKIN18.png";
import skin19 from "../../../assets/DesignAssets/SkinFemale/SKIN19.png";
import skin20 from "../../../assets/DesignAssets/SkinFemale/SKIN20.png";
import skin21 from "../../../assets/DesignAssets/SkinFemale/SKIN21.png";
import skin22 from "../../../assets/DesignAssets/SkinFemale/SKIN22.png";
import skin23 from "../../../assets/DesignAssets/SkinFemale/SKIN23.png";
import skin24 from "../../../assets/DesignAssets/SkinFemale/SKIN24.png";
import skin25 from "../../../assets/DesignAssets/SkinFemale/SKIN25.png";
import skin26 from "../../../assets/DesignAssets/SkinFemale/SKIN26.png";
import skin27 from "../../../assets/DesignAssets/SkinFemale/SKIN27.png";
import skin28 from "../../../assets/DesignAssets/SkinFemale/SKIN28.png";
import skin29 from "../../../assets/DesignAssets/SkinFemale/SKIN29.png";
import skin30 from "../../../assets/DesignAssets/SkinFemale/SKIN30.png";
import skin31 from "../../../assets/DesignAssets/SkinFemale/SKIN31.png";

import Clothes1 from "../../../assets/DesignAssets/Clothes/transparent.png";
import Clothes2 from "../../../assets/DesignAssets/Clothes/CLOTHES2.png";
import Clothes3 from "../../../assets/DesignAssets/Clothes/CLOTHES3.png";
import Clothes4 from "../../../assets/DesignAssets/Clothes/CLOTHES4.png";
import Clothes5 from "../../../assets/DesignAssets/Clothes/CLOTHES5.png";
import Clothes6 from "../../../assets/DesignAssets/Clothes/CLOTHES6.png";
import Clothes7 from "../../../assets/DesignAssets/Clothes/CLOTHES7.png";
import Clothes8 from "../../../assets/DesignAssets/Clothes/CLOTHES8.png";
import Clothes9 from "../../../assets/DesignAssets/Clothes/CLOTHES9.png";
import Clothes10 from "../../../assets/DesignAssets/Clothes/CLOTHES10.png";
import Clothes11 from "../../../assets/DesignAssets/Clothes/CLOTHES11.png";
import Clothes12 from "../../../assets/DesignAssets/Clothes/CLOTHES12.png";
import Clothes13 from "../../../assets/DesignAssets/Clothes/CLOTHES13.png";
import Clothes14 from "../../../assets/DesignAssets/Clothes/CLOTHES14.png";
import Clothes15 from "../../../assets/DesignAssets/Clothes/CLOTHES15.png";
import Clothes16 from "../../../assets/DesignAssets/Clothes/CLOTHES1.png";

const skinImg = [
  { key: 1, img: skin1, name: "skin1" },
  { key: 2, img: skin2, name: "skin2" },
  { key: 3, img: skin3, name: "skin3" },
  { key: 4, img: skin4, name: "skin4" },
  { key: 5, img: skin5, name: "skin5" },
  { key: 6, img: skin6, name: "skin6" },
  { key: 7, img: skin7, name: "skin7" },
  { key: 8, img: skin8, name: "skin8" },
  { key: 9, img: skin9, name: "skin9" },
  { key: 10, img: skin10, name: "skin10" },
  { key: 11, img: skin11, name: "skin11" },
  { key: 12, img: skin12, name: "skin12" },
  { key: 13, img: skin13, name: "skin13" },
  { key: 14, img: skin14, name: "skin14" },
  { key: 15, img: skin15, name: "skin15" },
  { key: 16, img: skin16, name: "skin16" },
  { key: 17, img: skin17, name: "skin17" },
  { key: 18, img: skin18, name: "skin18" },
  { key: 19, img: skin19, name: "skin19" },
  { key: 20, img: skin20, name: "skin20" },
  { key: 21, img: skin21, name: "skin21" },
  { key: 22, img: skin22, name: "skin22" },
  { key: 23, img: skin23, name: "skin23" },
  { key: 24, img: skin24, name: "skin24" },
  { key: 25, img: skin25, name: "skin25" },
  { key: 26, img: skin26, name: "skin26" },
  { key: 27, img: skin27, name: "skin27" },
  { key: 28, img: skin28, name: "skin28" },
  { key: 29, img: skin29, name: "skin29" },
  { key: 30, img: skin30, name: "skin30" },
  { key: 31, img: skin31, name: "skin31" },
];

const HeadGearImg = [
  { key: 1, img: Head1, name: "none" },
  { key: 2, img: Head2, name: "head2" },
  { key: 3, img: Head3, name: "head3" },
  { key: 4, img: Head4, name: "head4" },
  { key: 5, img: Head5, name: "head5" },
  { key: 6, img: Head6, name: "head6" },
  { key: 7, img: Head7, name: "head7" },
  { key: 8, img: Head8, name: "head8" },
  { key: 9, img: Head9, name: "head9" },
  { key: 10, img: Head10, name: "head10" },
  { key: 11, img: Head11, name: "head11" },
  { key: 12, img: Head12, name: "head12" },
  { key: 13, img: Head13, name: "head13" },
  { key: 14, img: Head14, name: "head14" },
  { key: 15, img: Head15, name: "head15" },
  { key: 16, img: Head16, name: "head16" },
  { key: 17, img: Head17, name: "head17" },
  { key: 18, img: Head18, name: "head18" },
  { key: 19, img: Head19, name: "head19" },
  { key: 20, img: Head20, name: "head20" },
  { key: 21, img: Head21, name: "head21" },
];

const clothsImg = [
  { key: 1, img: Clothes1, name: "none" },
  { key: 2, img: Clothes2, name: "clothes2" },
  { key: 3, img: Clothes3, name: "clothes3" },
  { key: 4, img: Clothes4, name: "clothes4" },
  { key: 5, img: Clothes5, name: "clothes5" },
  { key: 6, img: Clothes6, name: "clothes6" },
  { key: 7, img: Clothes7, name: "clothes7" },
  { key: 8, img: Clothes8, name: "clothes8" },
  { key: 9, img: Clothes9, name: "clothes9" },
  { key: 10, img: Clothes10, name: "clothes10" },
  { key: 11, img: Clothes11, name: "clothes11" },
  { key: 12, img: Clothes12, name: "clothes12" },
  { key: 13, img: Clothes13, name: "clothes13" },
  { key: 14, img: Clothes14, name: "clothes14" },
  { key: 15, img: Clothes15, name: "clothes15" },
  { key: 16, img: Clothes16, name: "clothes16" },
];

const backgroundImg = [
  { key: 1, img: Background1, name: "bckgrd1" },
  { key: 2, img: Background2, name: "bckgrd2" },
  { key: 3, img: Background3, name: "bckgrd3" },
  { key: 4, img: Background4, name: "bckgrd4" },
  { key: 5, img: Background5, name: "bckgrd5" },
  { key: 6, img: Background6, name: "bckgrd6" },
  { key: 7, img: Background7, name: "bckgrd7" },
  { key: 8, img: Background8, name: "bckgrd8" },
  { key: 9, img: Background9, name: "bckgrd9" },
  { key: 10, img: Background10, name: "bckgrd10" },
  { key: 11, img: Background11, name: "bckgrd11" },
  { key: 12, img: Background12, name: "bckgrd12" },
  { key: 13, img: Background13, name: "bckgrd13" },
  { key: 14, img: Background14, name: "bckgrd14" },
  { key: 15, img: Background15, name: "bckgrd15" },
  { key: 16, img: Background16, name: "bckgrd16" },
  { key: 17, img: Background17, name: "bckgrd17" },
  { key: 18, img: Background18, name: "bckgrd18" },
  { key: 19, img: Background19, name: "bckgrd19" },
  { key: 20, img: Background20, name: "bckgrd20" },
  { key: 21, img: Background21, name: "bckgrd21" },
  { key: 22, img: Background22, name: "bckgrd22" },
  { key: 23, img: Background23, name: "bckgrd23" },
  { key: 24, img: Background24, name: "bckgrd24" },
  { key: 25, img: Background25, name: "bckgrd25" },
  { key: 26, img: Background26, name: "bckgrd26" },
  { key: 27, img: Background27, name: "bckgrd27" },
  { key: 28, img: Background28, name: "bckgrd28" },
  { key: 29, img: Background29, name: "bckgrd29" },
  { key: 30, img: Background30, name: "bckgrd30" },
  { key: 31, img: Background31, name: "bckgrd31" },
  { key: 32, img: Background32, name: "bckgrd32" },
  { key: 33, img: Background33, name: "bckgrd33" },
  { key: 34, img: Background34, name: "bckgrd34" },
  { key: 35, img: Background35, name: "bckgrd35" },
  { key: 36, img: Background36, name: "bckgrd36" },
  { key: 37, img: Background37, name: "bckgrd37" },
  { key: 38, img: Background38, name: "bckgrd38" },
  { key: 39, img: Background39, name: "bckgrd39" },
  { key: 40, img: Background40, name: "bckgrd40" },
  { key: 41, img: Background41, name: "bckgrd41" },
  { key: 42, img: Background42, name: "bckgrd42" },
  { key: 43, img: Background43, name: "bckgrd43" },
  { key: 44, img: Background44, name: "bckgrd44" },
  { key: 45, img: Background45, name: "bckgrd45" },
  { key: 46, img: Background46, name: "bckgrd46" },
  { key: 47, img: Background47, name: "bckgrd47" },
  { key: 48, img: Background48, name: "bckgrd48" },
  { key: 49, img: Background49, name: "bckgrd49" },
  { key: 50, img: Background50, name: "bckgrd50" },
  { key: 51, img: Background51, name: "bckgrd51" },
  { key: 52, img: Background52, name: "bckgrd52" },
  { key: 53, img: Background53, name: "bckgrd53" },
  { key: 54, img: Background54, name: "bckgrd54" },
  { key: 55, img: Background55, name: "bckgrd55" },
  { key: 56, img: Background56, name: "bckgrd56" },
  { key: 57, img: Background57, name: "bckgrd57" },
  { key: 58, img: Background58, name: "bckgrd58" },
  { key: 59, img: Background59, name: "bckgrd59" },
];

const crossImg = [{ key: 1, img: Cross, name: "none" }];

interface RightgridPanelI {
  setCanvasObject: React.Dispatch<React.SetStateAction<breedRequestBody>>;
  canvasObject: breedRequestBody;
  nameObject: breedRequestBody;
  setNameObject: React.Dispatch<React.SetStateAction<breedRequestBody>>;
}

function LeftGridPanel(props: RightgridPanelI) {
  const [activeBackground, setActiveBackground] = useState<number>(0);
  const [activeSkin, setActiveSkin] = useState<number>(0);
  const [activeClothes, setActiveClothes] = useState<number>(0);
  const [activeMouthImg, setActiveMouth] = useState<number>(0);
  const [leftPanelValue, setLeftPanelValue] = useState<number>(0);
  const [activeHeadGear, setActiveHeadGear] = useState<number>(0);
  const handleChangeLeftPanel = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setLeftPanelValue(newValue);
  };
  const handleBackground = (item: any) => {
    const newCharacterObject = structuredClone(props.canvasObject);
    newCharacterObject.childTraits.background = item.img;
    props.setCanvasObject(newCharacterObject);

    const newCharacterObjectName = structuredClone(props.nameObject);
    newCharacterObjectName.childTraits.background = item?.name;
    props.setNameObject(newCharacterObjectName);

    setActiveBackground(item.key);
  };

  const handleHeadgear = (item: any) => {
    if (item.name === "none") {
      const newCharacterObject = structuredClone(props.canvasObject);
      newCharacterObject.childTraits.headgear = item.img;
      props.setCanvasObject(newCharacterObject);

      const newCharacterObjectName = structuredClone(props.nameObject);
      newCharacterObjectName.childTraits.headgear = item?.name;
      props.setNameObject(newCharacterObjectName);

      setActiveHeadGear(item.key);
    } else {
      const newCharacterObject = structuredClone(props.canvasObject);
      newCharacterObject.childTraits.headgear = item.img;
      props.setCanvasObject(newCharacterObject);

      const newCharacterObjectName = structuredClone(props.nameObject);
      newCharacterObjectName.childTraits.headgear = item?.name;
      props.setNameObject(newCharacterObjectName);

      setActiveHeadGear(item.key);
    }
  };
  const handleSkin = (item: any) => {
    const newCharacterObject = structuredClone(props.canvasObject);
    newCharacterObject.childTraits.skin = item.img;
    props.setCanvasObject(newCharacterObject);

    const newCharacterObjectName = structuredClone(props.nameObject);
    newCharacterObjectName.childTraits.skin = item?.name;
    props.setNameObject(newCharacterObjectName);

    setActiveSkin(item.key);
  };

  const handleClothes = (item: any) => {
    const newCharacterObject = structuredClone(props.canvasObject);
    newCharacterObject.childTraits.clothing = item.img;
    props.setCanvasObject(newCharacterObject);

    const newCharacterObjectName = structuredClone(props.nameObject);
    newCharacterObjectName.childTraits.clothing = item?.name;
    props.setNameObject(newCharacterObjectName);

    setActiveClothes(item.key);
  };

  return (
    <Box
      mt={1}
      // ml={2}
      sx={{
        background: "rgba(63, 63, 63, 0.9)",
        // height: "90%",
        borderRadius: "10px",
        pb: "4px",
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          variant="scrollable"
          value={leftPanelValue}
          // visibleScrollbar={true}
          onChange={handleChangeLeftPanel}
          aria-label="basic tabs example"
          TabScrollButtonProps={{
            sx: { color: "white" },
          }}
          TabIndicatorProps={{
            sx: { backgroundColor: "#6DF8FE" },
          }}
          sx={{
            "& button.Mui-selected": { color: "#6DF8FE" },
          }}
        >
          <Tab
            label="Background"
            sx={{
              color: "white",
              borderRight: "4px solid rgba(109, 248, 254, 0.6)",
              background: "#003548",
            }}
          />
          <Tab
            label="Skin"
            sx={{
              color: "white",
              borderRight: "4px solid rgba(109, 248, 254, 0.6)",
              background: "#003548",
            }}
          />
          <Tab
            label="HeadGear"
            sx={{
              color: "white",
              borderRight: "4px solid rgba(109, 248, 254, 0.6)",
              background: "#003548",
            }}
          />
          <Tab
            label=" Clothes"
            sx={{ color: "white", background: "#003548" }}
          />
        </Tabs>
      </Box>
      {/* ///////////////////////////////////////////  Background   ///////////////////////////////////////////////// */}
      <TabPanel value={leftPanelValue} index={0}>
        <Box
          sx={{ background: "background: rgba(0, 0, 0, 0.5)" }}
          minHeight="55vh"
        >
          <Grid
            container
            spacing={{ sm: 2 }}
            columns={{ sm: 9 }}
            pl={2}
            minHeight="55vh"
            maxHeight="55vh"
          >
            {backgroundImg.map((item, index) => (
              <Grid item sm={3} key={item.key}>
                {activeBackground == item.key ? (
                  <Box
                    width="6vw"
                    height="10vh"
                    className={styleGrids.grid}
                    key={item.key}
                    onClick={() => handleBackground(item)}
                    sx={{ border: "2px solid #6DF8FE" }}
                  >
                    <img
                      src={item.img}
                      alt=""
                      style={{ width: "6vw", height: "10vh" }}
                      key={index}
                    />
                  </Box>
                ) : (
                  <Box
                    width="6vw"
                    height="10vh"
                    className={styleGrids.grid}
                    key={item.key}
                    onClick={() => handleBackground(item)}
                  >
                    <img
                      src={item.img}
                      alt=""
                      style={{ width: "6vw", height: "10vh" }}
                      key={index}
                    />
                  </Box>
                )}
              </Grid>
            ))}
          </Grid>
        </Box>
      </TabPanel>
      {/* /////////////////////////////////////////////  skin  /////////////////////////////////////////////////////// */}
      <TabPanel value={leftPanelValue} index={1}>
        <Box
          sx={{ background: "background: rgba(0, 0, 0, 0.5)" }}
          minHeight="55vh"
        >
          <Grid
            container
            spacing={{ sm: 2 }}
            columns={{ sm: 9 }}
            pl={2}
            minHeight="15vh"
            maxHeight="55vh"
          >
            {skinImg.map((item, index) => (
              <Grid item sm={3} key={item.key}>
                {activeSkin == item.key ? (
                  <Box
                    width="6vw"
                    height="10vh"
                    className={styleGrids.grid}
                    key={item.key}
                    onClick={() => handleSkin(item)}
                    sx={{ border: "2px solid #6DF8FE" }}
                  >
                    <img
                      src={item.img}
                      alt=""
                      style={{ width: "6vw", height: "10vh" }}
                      key={index}
                    />
                  </Box>
                ) : (
                  <Box
                    width="6vw"
                    height="10vh"
                    className={styleGrids.grid}
                    key={item.key}
                    onClick={() => handleSkin(item)}
                  >
                    <img
                      src={item.img}
                      alt=""
                      style={{ width: "6vw", height: "10vh" }}
                      key={index}
                    />
                  </Box>
                )}
              </Grid>
            ))}
          </Grid>
        </Box>
      </TabPanel>
      {/* ///////////////////////////////////////   Head Gear  ///////////////////////////////////////////////////////// */}
      <TabPanel value={leftPanelValue} index={2}>
        <Box
          sx={{ background: "background: rgba(0, 0, 0, 0.5)" }}
          minHeight="55vh"
        >
          <Grid
            container
            spacing={{ sm: 2 }}
            columns={{ sm: 9 }}
            pl={2}
            minHeight="15vh"
            maxHeight="55vh"
          >
            {HeadGearImg.map((item, index) => (
              <Grid item sm={3} key={item.key}>
                {item.key === 1 ? (
                  <>
                    <Box
                      width="6vw"
                      height="10vh"
                      className={styleGrids.grid}
                      key={item.key}
                      onClick={() => handleHeadgear(item)}
                      // sx={{ border: "2px solid #6DF8FE" }}
                    >
                      <img
                        src={crossImg[0].img}
                        alt=""
                        style={{ width: "6vw", height: "10vh" }}
                        key={index}
                      />
                    </Box>
                  </>
                ) : activeHeadGear == item.key ? (
                  <Box
                    width="6vw"
                    height="10vh"
                    className={styleGrids.grid}
                    key={item.key}
                    onClick={() => handleHeadgear(item)}
                    sx={{ border: "2px solid #6DF8FE" }}
                  >
                    <img
                      src={item.img}
                      alt=""
                      style={{ width: "6vw", height: "10vh" }}
                      key={index}
                    />
                  </Box>
                ) : (
                  <Box
                    width="6vw"
                    height="10vh"
                    className={styleGrids.grid}
                    key={item.key}
                    onClick={() => handleHeadgear(item)}
                  >
                    <img
                      src={item.img}
                      alt=""
                      style={{ width: "6vw", height: "10vh" }}
                      key={index}
                    />
                  </Box>
                )}
              </Grid>
            ))}
          </Grid>
        </Box>
      </TabPanel>
      {/* ///////////////////////////////////////   Clothes  ///////////////////////////////////////////////////////// */}

      <TabPanel value={leftPanelValue} index={3}>
        <Box
          sx={{ background: "background: rgba(0, 0, 0, 0.5)" }}
          minHeight="55vh"
        >
          <Grid
            container
            spacing={{ sm: 2 }}
            columns={{ sm: 9 }}
            pl={2}
            maxHeight="55vh"
          >
            {clothsImg.map((item, index) => (
              <Grid item sm={3} key={item.key}>
                {item.key === 1 ? (
                  <>
                    <Box
                      width="6vw"
                      height="10vh"
                      className={styleGrids.grid}
                      key={item.key}
                      onClick={() => handleClothes(item)}
                      // sx={{ border: "2px solid #6DF8FE" }}
                    >
                      <img
                        src={crossImg[0].img}
                        alt=""
                        style={{ width: "6vw", height: "10vh" }}
                        key={index}
                      />
                    </Box>
                  </>
                ) : activeClothes == item.key ? (
                  <Box
                    width="6vw"
                    height="10vh"
                    className={styleGrids.grid}
                    key={item.key}
                    onClick={() => handleClothes(item)}
                    sx={{ border: "2px solid #6DF8FE" }}
                  >
                    <img
                      src={item.img}
                      alt=""
                      style={{ width: "6vw", height: "10vh" }}
                      key={index}
                    />
                  </Box>
                ) : (
                  <Box
                    width="6vw"
                    height="10vh"
                    className={styleGrids.grid}
                    key={item.key}
                    onClick={() => handleClothes(item)}
                  >
                    <img
                      src={item.img}
                      alt=""
                      style={{ width: "6vw", height: "10vh" }}
                      key={index}
                    />
                  </Box>
                )}
              </Grid>
            ))}
          </Grid>
        </Box>
      </TabPanel>
    </Box>
  );
}

export default LeftGridPanel;
export interface characterObjectI {
  issuer: string;
  attributes: {
    background: string;
    skin: string;
    eye: string;
    eyewear: string;
    mouth: string;
    clothing: string;
    eyes: string;
    headgear: string;
    accesories: string;
  };
}
