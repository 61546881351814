import { Box } from "@mui/material";
import ChamberImg from "../../../assets/BreedingAssets/Chamber.png";

interface Props {
  isScreenLessThan1725px: boolean;
}

const Chamber = ({ isScreenLessThan1725px }: Props) => {
  return (
    <Box width="100%" position="absolute" sx={{ m: 0, top: 120, left: "38%" }}>
      <Box>
        <img
          src={`${ChamberImg}`}
          alt=""
          width="15%"
          style={{
            maxHeight: isScreenLessThan1725px ? "80vh" : "90vh",
            minWidth: "20%",
          }}
        />
      </Box>
    </Box>
  );
};

export default Chamber;
