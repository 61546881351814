import { redirect } from "react-router-dom";

export const redirectIfNotLoggedIn = async () => {
  const isNotToken = !localStorage.getItem("token");
  console.log("isNotToken:", isNotToken);
  // if (isNotToken) {
  //   return redirect("/");
  // }
  return null;
};
