import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { io, Socket } from "socket.io-client";

interface initalStateI {
  socket: any;
}

const initialState: initalStateI = {
  socket: {},
};

const socketSlice = createSlice({
  name: "socketR",
  initialState,
  reducers: {
    connectSocket(state, action: PayloadAction<any>) {
      state.socket = action.payload;
    },
  },
});

export const { connectSocket } = socketSlice.actions;

export default socketSlice.reducer;
