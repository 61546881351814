import Styles from "./Card.module.css";
import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import { Typography, Box } from "@mui/material";

import useMediaQuery from "@mui/material/useMediaQuery";

function MobileCard({ imagen, info }) {
  const [show, setShown] = useState(false);
  const bigScreenCheck = useMediaQuery("(max-width:1725px)");

  return (
    <animated.div
      className={bigScreenCheck ? Styles.card : Styles["card-big"]}
      onMouseEnter={() => setShown(true)}
      onMouseLeave={() => setShown(false)}
    >
      <Box maxHeight={150}>
        <img
          style={{ marginTop: 10 }}
          src={imagen}
          alt={info.name}
          loading="lazy"
          crossOrigin="anonymous"
        />
      </Box>
      <Typography sx={{ marginTop: 6, fontSize: 18 }} variant="h5">
        {info.name}
      </Typography>
      {/* <Typography sx={{ marginTop: 0, fontSize: 11 }} variant="body">
        Score : 178.67056645
      </Typography>
      <Typography sx={{ marginTop: 0, fontSize: 11 }} variant="body">
        Rank : 3449/10000
      </Typography> */}
    </animated.div>
  );
}

export default MobileCard;
