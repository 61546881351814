import { Box, Button, Grid, Typography } from "@mui/material";
import Header from "../../../assets/BreedingAssets/header.png";
import Header2 from "../../../assets/BreedingAssets/heading2.png";

interface headerAndBreedButtonPropsI {
  handleOpenModal: () => void;
  isScreenLessThan1725px: boolean;
}

function HeaderAndBreedButton({
  handleOpenModal,
  isScreenLessThan1725px,
}: headerAndBreedButtonPropsI) {
  return (
    <Grid item sm={6}>
      <Box
        width="100%"
        borderRadius="8px"
        sx={{
          border: "1px solid #6DF8FE",
          my: 2,
          p: 2,
          background: "rgba(70, 70, 70, 0.8);",
        }}
      >
        <img src={`${Header}`} alt="" width="100%" />
      </Box>

      <Box
        justifyContent="center"
        // width="80%"
        borderRadius="8px"
        sx={{
          paddingInline: 2,
          p: 1,
          marginInline: 10,
          border: "1px solid #6DF8FE",
          background: "rgba(70, 70, 70, 0.8);",
          justifyContent: "center",
          // ml: isScreenLessThan1725px ? 7 : 0,
          color: "white",
          zIndex: 99,
          position: "relative",
          mt: isScreenLessThan1725px ? 0 : 4,
        }}
      >
        <img src={`${Header2}`} alt="" width="40%" /> {" :"}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: isScreenLessThan1725px ? "center" : "flex-start",
          mt: isScreenLessThan1725px ? 2 : 4,
        }}
      >
        <Button
          onClick={handleOpenModal}
          size={isScreenLessThan1725px ? "small" : "large"}
          sx={{
            opacity: 1,
            color: "white",
            background: "#36454F",
            mr: isScreenLessThan1725px ? 8 : 0,
            p: isScreenLessThan1725px ? 1 : 3,
            paddingInline: isScreenLessThan1725px ? 2.5 : 8,
            border: "1px solid #6DF8FE",
            "&:hover": {
              background: "rgba(109, 228, 264, 0.9)",
              color: "black",
            },
            fontSize: isScreenLessThan1725px ? 18 : 28,
            fontWeight: 600,
            ml: isScreenLessThan1725px ? "1%" : "38.5%",
          }}
        >
          <Typography sx={{ fontSize: 18, fontWeight: 600 }}>Breed</Typography>
        </Button>
      </Box>
    </Grid>
  );
}

export default HeaderAndBreedButton;
