import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentProcessNumber: "",
  yourProcessNumber: "",
  txnHash: "",
  loader: false,
};
export const setProcessQueue = createSlice({
  name: "gender",
  initialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action?.payload;
    },
    setCurentProcessNumber: (state, action) => {
      state.currentProcessNumber = action?.payload;
    },
    setYorProcessNumber: (state, action) => {
      state.yourProcessNumber = action?.payload;
      state.currentProcessNumber = action?.payload.currentlyProcessing;
    },
    setTxnHash: (state, action) => {
      state.txnHash = action?.payload;
    },
  },
});
export const {
  setLoader,
  setCurentProcessNumber,
  setTxnHash,
  setYorProcessNumber,
} = setProcessQueue.actions;

export default setProcessQueue.reducer;
