import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router";
import Canvas from "../Canvas/Canvas";
import RightGridPanel from "./GridPanels/RightGridPanels";
import LeftGridPanel from "./GridPanels/LeftGridPanels";
import Header2 from "../../assets/BreedingAssets/heading2.png";
import LogoutComp from "../../Components/Logout/Logout";
import Logo from "../../assets/BreedingAssets/logo.png";
import BreedingBackground from "../../assets/BreedingAssets/BreedingBackground.png";
import PotionCarousel from "../PotionCarousel/PotionCarousel";
import useMediaQuery from "@mui/material/useMediaQuery";
import styleGrids from "./DesignGrids.module.css";
import { useDispatch, useSelector } from "react-redux";
import { API, axiosRequestAuth } from "../../Components/api/api";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { setSnackbar } from "../../redux/Snackbar/SnackbarReducder";
import { connectSocket } from "../../redux/SocketReducer/SocketReducer";
import {
  setCurentProcessNumber,
  setLoader,
  setTxnHash,
  setYorProcessNumber,
} from "../../redux/QueueProcess/QueueProcessReducer";

import io from "socket.io-client";
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  Tab,
  Tabs,
  Checkbox,
  FormControlLabel,
  Modal,
} from "@mui/material";

import { setApiHit } from "../../redux/LoaderReducer/LoaderReducer";

const smallCheck = {
  paddingInline: 30,
};

const bigCheck = {
  paddingInline: 100,
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  width: 800,
  bgcolor: "rgba(109, 220, 254,1)",

  boxShadow: 24,
  p: 4,
};

const DesginGrids = () => {
  const [mintcheck, setMintCheck] = useState<boolean>(false);

  const bigScreenCheck = useMediaQuery("(max-width:1725px)");
  const paddingCheck = useMediaQuery("(max-width:1230px)");
  const navigate = useNavigate();
  const [modalOpen, setOpenModal] = useState<boolean>(false);
  const [modalOpen2, setOpenModal2] = useState<boolean>(false);
  const femaleToken = useSelector((state: any) => state.gender.female);
  const maleToken = useSelector((state: any) => state.gender.male);
  const potionToken = useSelector((state: any) => state.potion?.potionId);
  const [checkBoxCheck1, setcheckBoxCheck1] = useState(true);
  const [checkBoxCheck2, setcheckBoxCheck2] = useState(false);
  const dispatch = useDispatch();

  let socketio = useSelector((state: any) => state.socketR.socket);

  useEffect(() => {
    dispatch(setApiHit(false));
  }, []);
  const [nftHash, setNftHash] = useState<any>("");
  const [characterObjectState, setCharacterObjectState] =
    useState<breedRequestBody>({
      male: {
        tokenId: maleToken,
      },
      female: {
        tokenId: femaleToken,
      },
      potion: {
        tokenId: potionToken,
      },
      childTraits: {
        gender: "MALE",
        background: "",
        skin: "",
        eyewear: "none",
        mouth: "",
        clothing: "none",
        eyes: "",
        headgear: "none",
        accesories: "none",
      },
      uri: "",
    });

  const [characterObjectName, setCharacterObjectName] =
    useState<breedRequestBody>({
      male: {
        tokenId: maleToken,
      },
      female: {
        tokenId: femaleToken,
      },
      potion: {
        tokenId: potionToken,
      },
      childTraits: {
        gender: "MALE",
        background: "",
        skin: "",
        eyewear: "none",
        mouth: "",
        clothing: "none",
        eyes: "",
        headgear: "none",
        accesories: "none",
      },
      uri: "",
    });

  const socketsReq = (socket: any) => {
    dispatch(connectSocket(socket));

    socket.on("connect", () => {
      console.log("socket Connected");
    });

    socket.on("disconnect", (reason: any) => {
      console.log("socket is disconnected", reason);
    });

    socket.on("burn/signed", (data: any) => {
      setMintCheck(true);
      dispatch(setLoader(true));
      console.log(" burn", data?.didUserSign);
      if (data?.didUserSign === false) {
        setMintCheck(false);
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Burn request rejected from Xumm",
          })
        );
        setOpenModal(false);
      } else {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Burn request accepted from Xumm",
          })
        );
      }
    });
    socket.on("mint/signed", (data: any) => {
      setOpenModal(false);

      console.log(" sign", data?.didUserSign);
      if (data?.didUserSign === false) {
        setMintCheck(false);
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Mint request rejected from Xumm",
          })
        );
      } else {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: " Mint request accepted from Xumm",
          })
        );
        navigate("/transaction-history");
      }
    });
    socket.on("queue/progress", (data: any) => {
      setMintCheck(false);
      dispatch(setCurentProcessNumber(data.currentlyProcessing));
      console.log(" Queue Processing", data);
    });
    socket.on("queue/added", (data: any) => {
      dispatch(setYorProcessNumber(data.newMintQueueNumber));
      dispatch(setCurentProcessNumber(data.currentlyProcessing));

      console.log(" Queue Added", data);
    });

    socket.on("mint/completed", (data: any) => {
      console.log("mint/completed", data);
      if (data.didFail) {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage:
              "Something went wrong, please contact support@luxlionsnft.com",
          })
        );
      } else {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Cub Minted successfully!",
          })
        );
      }
      dispatch(setLoader(false));
      dispatch(connectSocket({}));
      socket.disconnect();
      setMintCheck(false);
    });
  };

  const checkBoxFunc1 = () => {
    setcheckBoxCheck1(true);
    setcheckBoxCheck2(false);

    const newCharacterObjectName = structuredClone(characterObjectName);
    const newCharacterObjectState = structuredClone(characterObjectState);

    newCharacterObjectName.childTraits.gender = "MALE";

    newCharacterObjectState.childTraits.gender = "MALE";

    setCharacterObjectName(newCharacterObjectName);

    setCharacterObjectState(newCharacterObjectState);
  };

  const checkBoxFunc2 = () => {
    setcheckBoxCheck1(false);
    setcheckBoxCheck2(true);
    const newCharacterObjectName = structuredClone(characterObjectName);
    const newCharacterObjectState = structuredClone(characterObjectState);

    newCharacterObjectName.childTraits.gender = "FEMALE";

    newCharacterObjectState.childTraits.gender = "FEMALE";

    setCharacterObjectName(newCharacterObjectName);
    setCharacterObjectState(newCharacterObjectState);
  };

  const handleCreate = () => {
    console.log("Clickes");

    characterObjectName.uri = characterObjectState.uri;
    if (potionToken === "") {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Please select Potion first",
        })
      );
    } else if (characterObjectName.childTraits.background === "") {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Please select background",
        })
      );
    } else if (characterObjectName.childTraits.eyes === "") {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Please select Eyes",
        })
      );
    } else if (characterObjectName.childTraits.mouth === "") {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Plese select Mouth",
        })
      );
    } else if (characterObjectName.childTraits.skin === "") {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Please select Skin",
        })
      );
    } else {
      try {
        const token: any = localStorage.getItem("token");
        socketio = io(API.socket_backend, {
          autoConnect: false,
          auth: { token },
        });

        // useDispatch(setSocketConnected());
        socketsReq(socketio);

        socketio.connect();
      } catch (e) {
        console.log(e, "error in socket");
      }
      createNFT();
    }
  };

  async function createNFT() {
    dispatch(setLoader(true));

    try {
      const response: any = await axiosRequestAuth(
        "post",
        API.cubApiURL,
        characterObjectName,
        undefined
      );

      setOpenModal(true);
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: response.data.message,
        })
      );
      dispatch(setLoader(false));
    } catch (err: any) {
      console.log("data is Error", err?.response.data.message);
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",

          snackbarMessage: `${err.response.data.message} please log out and try again`,
        })
      );
      dispatch(setLoader(false));
    }
  }

  return (
    <>
      <div>
        <img
          src={BreedingBackground}
          alt=""
          style={{ width: "100%", height: "100vh", position: "relative" }}
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            width: "100%",
          }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              sm={3}
              sx={{ paddingTop: 0, display: "flex" }}
              justifyContent="center"
            >
              <Box
                sx={{ paddingTop: 2, cursor: "pointer" }}
                onClick={() => navigate("/breeding")}
              >
                <img src={`${Logo}`} alt="" width="100%" />
              </Box>
            </Grid>

            <Grid item sm={6} sx={{ ml: 0 }} justifyContent="right">
              <Box
                width="100%"
                borderRadius="8px"
                sx={{
                  border: "1px solid #6DF8FE",
                  mt: 2,
                  height: "6vh",
                  background: "rgba(70, 70, 70, 0.8);",
                }}
              >
                <Box
                  width="80%"
                  borderRadius="8px"
                  sx={{
                    paddingInline: 2,
                    pt: 1,
                    background: "rgba(70, 70, 70, 0.8);",

                    ml: 0,
                    color: "white",
                    zIndex: 99,
                    position: "relative",
                  }}
                >
                  <img src={`${Header2}`} alt="" width="50%" /> {" :"}
                </Box>
              </Box>
            </Grid>

            <Grid
              item
              sm={3}
              sx={{ mt: 3, display: "flex" }}
              justifyContent="center"
            >
              <Box sx={{ textAlign: "right" }}>
                <LogoutComp />
              </Box>
            </Grid>

            <Grid item sm={12}>
              {/* /////////////////////////////////////////// Choose your traits ////////////////////////////////////////////// */}
              <Grid container sx={{ mt: -4 }}>
                <Grid item sm={4}></Grid>
                <Grid item sm={4} textAlign="center">
                  {" "}
                  <Box
                    sx={{
                      background: "rgba(63, 63, 63, 0.9)",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                      height: "4vh",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: 18,
                        fontWeight: 600,
                        textShadow: "0px 4px 3px rgba(109, 248, 254, 0.5);",
                      }}
                    >
                      CHOOSE YOUR TRAITS
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={4}></Grid>
              </Grid>

              <Box style={paddingCheck ? smallCheck : bigCheck}>
                <Grid
                  container
                  sx={{
                    background: " rgba(109, 248, 254, 0.6)",
                    borderRadius: "20px",
                    minHeight: "68vh",
                    p: 2,
                    // pb: "15px",
                    px: 2,
                  }}
                >
                  {/* /////////////////////////Left Panel////////////////////// */}
                  <Grid item sm={4} p={0}>
                    <LeftGridPanel
                      canvasObject={characterObjectState}
                      setCanvasObject={setCharacterObjectState}
                      nameObject={characterObjectName}
                      setNameObject={setCharacterObjectName}
                    />
                  </Grid>
                  {/* /////////////////////////   Canvas   ////////////////////// */}
                  <Grid
                    item
                    sm={4}
                    sx={{ display: "flex" }}
                    justifyContent="center"
                  >
                    <Box>
                      <Canvas charObj={characterObjectState} />
                      <Box
                        textAlign="right"
                        // direction="row"
                        justifyContent="space-between"
                        sx={{
                          mt: 2,

                          display: "flex",
                          flexDirection: "row",
                        }}
                        className={styleGrids.box}
                      >
                        <FormControlLabel
                          value="top"
                          sx={{ color: "white", ml: 1 }}
                          control={
                            <Checkbox
                              checked={checkBoxCheck1}
                              onClick={checkBoxFunc1}
                              sx={{
                                color: "white",

                                "&.Mui-checked": {
                                  color: "#6DF8FE",
                                },
                              }}
                              size="small"
                            />
                          }
                          label={
                            <Typography
                              sx={{ fontSize: 18, fontFamily: "jedi" }}
                            >
                              MALE
                            </Typography>
                          }
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="top"
                          sx={{ color: "white" }}
                          control={
                            <Checkbox
                              checked={checkBoxCheck2}
                              onClick={checkBoxFunc2}
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#6DF8FE",
                                },
                              }}
                              size="small"
                            />
                          }
                          label={
                            <Typography
                              sx={{ fontSize: 18, fontFamily: "jedi" }}
                            >
                              FEMALE
                            </Typography>
                          }
                          labelPlacement="end"
                        />
                      </Box>
                      <Box textAlign="center" sx={{ mt: 2 }}>
                        <Button
                          variant="contained"
                          sx={{
                            color: "white",
                            background: "rgba(70, 70, 70, 0.8)",
                            border: "1px solid #ffff",
                            "&:hover": {
                              background: "#36454F",
                            },
                          }}
                          onClick={handleCreate}
                        >
                          CREATE
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                  {/* /////////////////////////  Right Panel   ////////////////////// */}
                  <Grid item sm={4}>
                    <RightGridPanel
                      canvasObject={characterObjectState}
                      setCanvasObject={setCharacterObjectState}
                      nameObject={characterObjectName}
                      setNameObject={setCharacterObjectName}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>

          {/* ///////////////////////////////  Bottom grid  /////////////////////////////////////// */}
          {/* <Grid container spacing={2} sx={{ mt: 6 }}>
            <Grid item sm={3}></Grid>
            <Grid item sm={6}>
              <Box width="100%">
                {bigScreenCheck ? (
                  <Box
                    sx={{
                      background: "rgba(217, 217, 217, 0.8);",
                      borderRadius: "15px",
                      width: "100%",
                      height: 60,
                      zIndex: 99,
                      position: "relative",
                      mt: -6,
                    }}
                  >
                    <PotionCarousel />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      background: "rgba(217, 217, 217, 0.8);",
                      borderRadius: "15px",
                      width: "100%",
                      height: 60,
                      zIndex: 99,
                      position: "relative",
                      p: 0,
                      mt: -6,
                    }}
                  >
                    <PotionCarousel />
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item sm={3}></Grid>
          </Grid> */}
        </div>
      </div>
      <>
        <Modal open={modalOpen}>
          <Box sx={style}>
            {mintcheck === true ? (
              <>
                <Typography
                  id="modal-modal-title"
                  variant="h5"
                  component="h2"
                  sx={{ fontFamily: "Jedi", fontWeight: 600 }}
                >
                  {
                    "Mint request generated. Awaiting xumm approval! (do not refresh page)"
                  }
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  id="modal-modal-title"
                  variant="h5"
                  component="h2"
                  sx={{ fontFamily: "Jedi", fontWeight: 600 }}
                >
                  {
                    "Burn request generated. Awaiting xumm approval!  (do not refresh page)"
                  }
                </Typography>
              </>
            )}
          </Box>
        </Modal>
      </>
    </>
  );
};
export default DesginGrids;

export interface characterObjectI {
  issuer: string;
  attributes: {
    background: string;
    skin: string;
    eyewear: string;
    mouth: string;
    clothing: string;
    eyes: string;
    headgear: string;
    accesories: string;
  };
}

export interface breedRequestBody {
  male: {
    tokenId: string;
  };
  female: {
    tokenId: string;
  };
  potion: {
    tokenId: string;
  };
  childTraits: traitsI;
  uri: string;
}

export interface traitsI {
  gender: Gender;
  background: string;
  skin: string;
  eyewear: string;
  mouth: string;
  clothing: string;
  eyes: string;
  headgear: string;
  accesories: string;
}

type Gender = "MALE" | "FEMALE";
