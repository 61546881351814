import PotionCarousel from "../../Components/PotionCarousel/PotionCarousel";
import BreedingBackground from "../../assets/BreedingAssets/BreedingBackground.png";

import Logo from "../../assets/BreedingAssets/logo.png";
import {
  Box,
  Grid,
  Button,
  Typography,
  Modal,
  CircularProgress,
} from "@mui/material";
import LogoutComp from "../../Components/Logout/Logout";

import { useSelector, useDispatch } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useState } from "react";
import { setSnackbar } from "../../redux/Snackbar/SnackbarReducder";
import { setLoading } from "../../redux/LoaderReducer/LoaderReducer";
import { axiosRequest } from "../api/api";
import { setNFT } from "../../redux/NFTReducer/NFTReducer";
import Chamber from "./Chamber/Chamber";
import BubbleImage from "./BubbleImage/BubbleImage";
import HeaderAndBreedButton from "./HeaderAndBreedButton/HeaderAndBreedButton";
import BreedingButton from "./BreedingButton/BreedingButton";
import ChooseMaleNft from "./ChooseNft/ChooseMaleNft";
import ChooseFemaleNft from "./ChooseNft/ChooseFemaleNft";

const Breeding = () => {
  const accountNum = localStorage.getItem("accnum");
  const isScreenLessThan1725px = useMediaQuery("(max-width:1725px)");
  const [modalOpen, setOpenModal] = useState<boolean>(false);
  const nfts = useSelector((state: any) => state.nfts?.nftObj);
  const potionToken = useSelector((state: any) => state.potion?.potionId);
  const maleToken = useSelector((state: any) => state.gender?.male);
  const femaleToken = useSelector((state: any) => state.gender?.female);

  const [potionSelectedState, setPotionSelectedState] =
    useState<boolean>(false);

  const [isFemaleNftSelected, setIsFemaleNftSelected] =
    useState<boolean>(false);

  const [isMaleNftSelected, setIsMaleNftSelected] = useState<boolean>(false);

  const firstHitApi2 = useSelector((state: any) => state.loader?.firstHitApi);

  const loadingState = useSelector((state: any) => state.loader?.loading);

  const dispatch = useDispatch();

  const areMaleNfts = !!nfts?.maleNfts?.length;
  const areFemaleNfts = !!nfts?.femaleNfts?.length;

  useEffect(() => {
    if (potionToken != "") {
      setPotionSelectedState(true);
    } else {
      setPotionSelectedState(false);
    }
    console.log(isFemaleNftSelected, "isFemaleNftSelected", isMaleNftSelected);

    if (maleToken === "" || maleToken === undefined) {
      setIsMaleNftSelected(false);
    } else {
      setIsMaleNftSelected(true);
    }

    if (femaleToken === "" || femaleToken === undefined) {
      setIsFemaleNftSelected(false);
      console.log();
    } else {
      setIsFemaleNftSelected(true);
    }
  }, [potionToken, maleToken, femaleToken]);

  useEffect(() => {
    if (firstHitApi2 === false) {
      getNFT(accountNum);
    }
  }, []);

  async function getNFT(data: any) {
    dispatch(setLoading(true));
    try {
      const response: any = await axiosRequest(
        "get",
        `${getNftURL}${data}`,
        undefined,
        undefined
      );

      dispatch(setNFT(response));
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      console.error(error);
    }
  }

  const handleOpenModal = () => {
    console.log(isFemaleNftSelected, "yeahehhhaeaeh", isMaleNftSelected);
    console.log(femaleToken, "token");

    if (potionSelectedState === false) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: `Please select potion NFT `,
        })
      );
      return;
    }

    if (isMaleNftSelected === false) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: `Please select a Lion `,
        })
      );
      return;
    }

    if (isFemaleNftSelected === false) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: `Please select a Lioness `,
        })
      );
      return;
    }
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        <img
          src={BreedingBackground}
          alt=""
          style={{ width: "100%", height: "100vh" }}
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            width: "100%",
          }}
        >
          {loadingState ? (
            <Box
              minHeight={"500px"}
              maxHeight={"500px"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <CircularProgress size={70} sx={{ color: "white" }} />
            </Box>
          ) : (
            <Grid container style={{ zIndex: 0 }}>
              <Chamber isScreenLessThan1725px={isScreenLessThan1725px} />

              <BubbleImage isScreenLessThan1725px={isScreenLessThan1725px} />

              <Grid
                item
                sm={3}
                sx={{ paddingTop: 4, display: "flex" }}
                justifyContent="center"
              >
                <Box>
                  <img src={`${Logo}`} alt="" width="100%" />
                </Box>
              </Grid>

              <HeaderAndBreedButton
                isScreenLessThan1725px={isScreenLessThan1725px}
                handleOpenModal={handleOpenModal}
              />

              {/* ////////////////////////// big screen crads grid////////////////////////// */}
              <Grid
                item
                sm={3}
                sx={{ paddingTop: 4, display: "flex" }}
                justifyContent="center"
              >
                <Box sx={{ textAlign: "right", minHeight: 5 }}>
                  <LogoutComp />
                </Box>
              </Grid>

              <Grid
                item
                sm={4}
                sx={{ px: 2, pt: CAROUSEL_TOP_PADDING, display: "flex" }}
                justifyContent="center"
              >
                {/* ///////////////////////////////// Left panel/////////////////////////////////////// */}
                {loadingState ? (
                  <Box
                    minHeight={"50px"}
                    maxHeight={"500px"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <CircularProgress size={70} sx={{ color: "white" }} />
                  </Box>
                ) : (
                  <ChooseMaleNft
                    nfts={nfts}
                    areMaleNfts={areMaleNfts}
                    isScreenLessThan1725px={isScreenLessThan1725px}
                  />
                )}
              </Grid>
              <Grid item sm={4} sx={{ pt: 2, ml: 0, textAlign: "center" }}>
                <Box width="100%"></Box>
              </Grid>
              <Grid
                item
                sm={4}
                sx={{ pt: CAROUSEL_TOP_PADDING, display: "flex" }}
                justifyContent="center"
              >
                {/* /////////////////////////////////////// Right panel  ////////////////////////////////////////////// */}
                {loadingState ? (
                  <Box
                    minHeight={"50px"}
                    maxHeight={"500px"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <CircularProgress size={70} sx={{ color: "white" }} />
                  </Box>
                ) : (
                  <ChooseFemaleNft
                    isScreenLessThan1725px={isScreenLessThan1725px}
                    nfts={nfts}
                    areFemaleNfts={areFemaleNfts}
                  />
                )}
              </Grid>
              <Box width="100%">
                <Grid container>
                  {isScreenLessThan1725px ? (
                    <>
                      <Grid item sm={3}></Grid>

                      <Grid item sm={6}>
                        <Box
                          sx={{
                            background: "rgba(217, 217, 217, 0.8);",
                            borderRadius: "15px",
                            width: "100%",
                            height: 65,
                            zIndex: 99,
                            position: "relative",
                            mt: "5%",
                          }}
                        >
                          <PotionCarousel />
                        </Box>

                        <Grid item sm={3}></Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item sm={3}></Grid>

                      <Grid item sm={6}>
                        <Box
                          sx={{
                            background: "rgba(217, 217, 217, 0.8);",
                            borderRadius: "15px",
                            width: "100%",
                            height: 75,
                            zIndex: 99,
                            position: "relative",
                            p: 2,
                            mt: "5%",
                          }}
                        >
                          <PotionCarousel />
                        </Box>
                      </Grid>

                      <Grid item sm={3}></Grid>
                    </>
                  )}
                </Grid>
              </Box>
            </Grid>
          )}
        </div>
        <Modal open={modalOpen} onClose={handleCloseModal}>
          <Box sx={modalStyles}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Are you sure you want to breed?
            </Typography>

            <Box mt={2}>
              <BreedingButton />

              <Button
                variant="contained"
                onClick={handleCloseModal}
                sx={{
                  opacity: 1,
                  color: "white",
                  background: "#36454F",
                  mr: 0,
                  mt: 1,
                  border: "1px solid #ffff",
                  "&:hover": {
                    background: "rgba(109, 258, 264, 0.6)",
                    color: "black",
                  },
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>
      </div>
    </>
  );
};
export default Breeding;

const CAROUSEL_TOP_PADDING = 0;

const modalStyles = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  width: 400,
  bgcolor: "rgba(109, 220, 254,1)",
  boxShadow: 24,
  p: 4,
};

const getNftURL = "api/luxlion/nfts?userAccount=";

let isMaleNftSelected = false;
let isFemaleNftSelected = false;
