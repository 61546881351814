import DesginGrids from "../../Components/DesignGrids/DesginGrids";
import MobilView from "./CharacterDesignMobileView/CharacterDesignMobileView";
import useMediaQuery from "@mui/material/useMediaQuery";

const CharacterDesign = () => {
  const mobileViewCheck = useMediaQuery("(min-width:1023px)");

  return <div>{mobileViewCheck ? <DesginGrids /> : <MobilView />}</div>;
};

export default CharacterDesign;
