import { Button } from "@mui/material";
import { NavigateFunction, useNavigate } from "react-router-dom";

const BreedingButton = () => {
  const navigate = useNavigate();
  return (
    <Button
      sx={{
        opacity: 1,
        color: "white",
        background: "#36454F",
        mr: 1,
        mt: 1,
        border: "1px solid #ffff",
        "&:hover": {
          background: "rgba(109, 258, 264, 0.6)",
          color: "black",
        },
      }}
      onClick={() => {
        navigate("/characterBuilding");
      }}
    >
      Breed
    </Button>
  );
};

export default BreedingButton;
