import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  male: "",
  female: "",
};
export const setGenderSlice = createSlice({
  name: "gender",
  initialState,
  reducers: {
    setMale: (state, action) => {
      state.male = action?.payload;
    },
    setFemale: (state, action) => {
      state.female = action?.payload;
    },
  },
});
export const { setMale, setFemale } = setGenderSlice.actions;

export default setGenderSlice.reducer;
