import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  firstHitApi: false,
};
export const setLoadingSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action?.payload;
    },
    setApiHit: (state, action) => {
      state.firstHitApi = action?.payload;
    },
  },
});
export const { setLoading, setApiHit } = setLoadingSlice.actions;

export default setLoadingSlice.reducer;
