import useMediaQuery from "@mui/material/useMediaQuery";
import { createRemoteJWKSet, jwtVerify } from "jose";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavigateFunction, Outlet, useNavigate } from "react-router-dom";
import { XummPkce } from "xumm-oauth2-pkce";
import { logout } from "../redux/LoginReducers/LoginReducers";
import { setFemale, setMale } from "../redux/GenderReducer/GenderReducer";
import { setNFT } from "../redux/NFTReducer/NFTReducer";
import { Dispatch } from "@reduxjs/toolkit";

const XUMM_API_KEY: any = process.env.REACT_APP_XUMM_API_KEY;
const xumm2 = new XummPkce(XUMM_API_KEY);

const AppWrapper = () => {
  const smallScreenCheck = useMediaQuery("(min-width:450px)");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const LoginObj = useSelector((state: any) => state.xumm.xumObj);

  useEffect(() => {
    if (smallScreenCheck === false) {
      // verifyJWT(LoginObj, dispatch, navigate);
    }
  }, []);

  return (
    <>
      <Outlet />
    </>
  );
};

export default AppWrapper;

const XUMM_JWKS_URL = "https://oauth2.xumm.app/certs";

function getXummJwks() {
  return createRemoteJWKSet(new URL(XUMM_JWKS_URL));
}

async function verifyJWT(
  LoginObjArg: any,
  dispatch: Dispatch,
  navigateArg: NavigateFunction
) {
  try {
    const xummJwk = getXummJwks();

    try {
      await jwtVerify(LoginObjArg.jwt, xummJwk);
    } catch (e) {
      xumm2.logout();
      dispatch(logout());
      dispatch(setMale(""));
      dispatch(setFemale(""));
      dispatch(setNFT({}));
      localStorage.setItem("token", "");
      localStorage.setItem("accnum", "");
      navigateArg("/");
      console.log(e, "error authenticateJWT");
    }
  } catch (e) {
    console.error(e, "error authenticateJWT");
  }
}
