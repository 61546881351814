import Carousel from "react-spring-3d-carousel";
import { useState, useEffect } from "react";
import { config } from "react-spring";
import { Box } from "@mui/material";
import { setFemale } from "../../redux/GenderReducer/GenderReducer";
import { useSelector, useDispatch } from "react-redux";

export default function FemaleCarousel(props: any) {
  useEffect(() => {}, [props.offset, props.showArrows]);

  return (
    <div
      style={{ width: props.width, height: props.height, margin: props.margin }}
    >
      <Carousel
        slides={props.cards}
        goToSlide={props.femaleCount}
        offsetRadius={2}
        showNavigation={false}
        animationConfig={config.gentle}
      />
    </div>
  );
}
