import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  potionId: "",
};
export const setPotionlice = createSlice({
  name: "potion",
  initialState,
  reducers: {
    setPotionId: (state, action) => {
      state.potionId = action?.payload;
    },
  },
});
export const { setPotionId } = setPotionlice.actions;

export default setPotionlice.reducer;
